import React from 'react'
import "@dotlottie/player-component";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link } from 'react-router-dom'

const about = () => {
  return (
    <>
    {/* About Us start */}
    <section id="home" className="about-us inner">
      <div className="container">
        <div className="about-us-content">
          <div className="row">
            <div className="col-sm-12">
              <div className="single-about-us">
                <div className="about-us-txt">
                  <h2>About Us</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-0">
              <div className="single-about-us"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*About Us end */}


    {/* Booking Section Start */}
      <div id="booking">
        <div className="container">
          <div className="section-header">
            {/* <h2>About US</h2>
            <p>Welcome to TravelFly Hub – your ultimate travel companion!</p>
            <p>At TravelFly Hub, we believe that travel is not just about reaching a destination; it’s about the journey, the experiences, and the memories you create along the way. Established with a passion for exploring the world, our mission is to inspire and enable every wanderer to embark on their own unique adventure.</p>
            <h6>Our Story</h6>
            <p> Our journey began with a simple idea: to make travel more accessible and enjoyable for everyone. Founded by a team of avid travelers and tech enthusiasts, TravelFly Hub was born out of a shared love for discovering new places and a desire to help others do the same. Over the years, we have evolved into a comprehensive travel platform, offering everything from flight bookings to curated travel guides.</p>
            <h6>Our Mission</h6>
            <p>We are dedicated to providing you with the best travel deals, insightful travel tips, and a seamless booking experience. Our goal is to be your go-to resource for all things travel, ensuring that your trips are hassle-free and filled with unforgettable moments. Whether you are planning a weekend getaway or a month-long expedition, we have got you covered.</p> */}
            
            <h5>Do your travel dreams include scaling the Great Wall or feeling the golden sand between your toes on a secluded beach? At Travel Fly Hub, we are all about helping you check these travel dreams off your list. </h5>
            <p>Founded by globetrotters like you, Travel Fly Hub is your travel planning companion who shares the same love for adventures as you. We help you find the best travel deals, insightful tips, and a seamless booking experience, ensuring your trips are stress-free and overflowing with unforgettable moments. Whether you're planning a weekend getaway or a month-long expedition, Travel Fly Hub has got you covered.</p>
            <h5>Explore our deals and start planning for your next trip today!</h5>
            <h6>Why Choose Us?</h6>
            <ol>
              <li><strong>1. Unbeatable Prices:</strong> We partner with top airlines and travel service providers to bring you the best deals available.</li>
              <li><strong>2. Expert Guidance:</strong> Our team of travel experts is always here to offer personalized advice and recommendations.</li>
              <li><strong>3. User-Friendly Experience:</strong> Our website is designed to be intuitive and easy to navigate, making your booking process quick and straightforward.</li>
              <li><strong>4. Comprehensive Support:</strong> From planning your trip to getting back home, our customer support team is here to assist you every step of the way.</li>
            </ol>
            {/* <h6>Join Us on the Journey</h6>
            <p>At TravelFly Hub, we believe that travel has the power to transform lives. It opens up new perspectives, fosters connections, and creates lifelong memories. We invite you to join our community of explorers and start your journey with us. Let's make every trip an adventure worth remembering.</p>
            <p>Thank you for choosing TravelFly Hub. We look forward to being a part of your travel stories.</p> */}
          </div>
        </div>
      </div>


    {/* service start */}
    <section id="service" className="service">
        <div className="container">
          <div className="service-counter text-center">
            <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="single-service-box">
                <div className="statistics-img">
                  <dotlottie-player
                    src="https://lottie.host/f468d04a-dd68-4700-abeb-4e80f4cef8d0/UssScqypXj.json"
                    background="transparent"
                    speed="1"
                    style={{ height: "105px", width: "105px" }}
                    loop
                    autoplay
                  />
                </div>
                <div className="service-content">
                  <h2>
                    <Link to="/booking"> online flight booking </Link>
                  </h2>
                  <p>
                    Find your perfect flight & take off on adventurous journeys.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="single-service-box">
                <div className="service-img">
                  <script
                    src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs"
                    type="module"
                  ></script>

                  <dotlottie-player
                    src="https://lottie.host/385194ea-a017-46e9-aad3-9fa0f4666aff/hdMFneD0kY.json"
                    background="transparent"
                    speed="1"
                    style={{ height: "80px", width: "95px" }}
                    loop
                    autoplay
                  />
                </div>
                <div className="service-content">
                  <h2>
                    <Link to="/booking"> book top className hotel </Link>
                  </h2>
                  <p>Book your dream hotel stay in an instant.</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="single-service-box">
                <div className="service-img">
                  <dotlottie-player
                    src="https://lottie.host/dca08d13-cbea-4c02-8c0a-e24c7669c7b1/dgU4e9B67S.json"
                    background="transparent"
                    speed="1"
                    style={{ width: "105px", height: "105px" }}
                    loop
                    autoplay
                  />
                </div>
                <div className="service-content">
                  <h2>
                    <Link to="/booking"> Book a Perfect Ride </Link>
                  </h2>
                  <p>Taxis, cars & more for your seamless by-road trips.</p>
                </div>
              </div>
            </div>
          </div>  
          </div>
        </div>
    </section>


    {/*Gallery start */}
    <section id="gallery" className="gallery">
        <div className="container">
          <div className="gallery-details">
            <div className="gallary-header text-center">
              <h2>Find Your Perfect Escape</h2>
              <p>
                Where will you go next? Explore a world of exciting
                destinations!
              </p>
            </div>
            <div className="gallery-box">
              <div className="gallery-content">
                <div className="filtr-container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="filtr-item">
                        <img src="assets/images/g1.jpg" alt="portfolio image" />
                        <div className="item-title">
                          <Link to="#"> china </Link>
                          <p>
                            <span>20 tours</span>
                            <span>15 places</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="filtr-item">
                        <img src="assets/images/g2.jpg" alt="portfolio image" />
                        <div className="item-title">
                          <Link to="#"> venuzuala </Link>
                          <p>
                            <span>12 tours</span>
                            <span>9 places</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="filtr-item">
                        <img src="assets/images/g3.jpg" alt="portfolio image" />
                        <div className="item-title">
                          <Link to="#"> brazil </Link>
                          <p>
                            <span>25 tours</span>
                            <span>10 places</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="filtr-item">
                            <img
                              src="assets/images/g4.jpg"
                              alt="portfolio image"
                            />
                            <div className="item-title">
                              <Link to="#"> australia </Link>
                              <p>
                                <span>18 tours</span>
                                <span>9 places</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="filtr-item">
                            <img
                              src="assets/images/g5.jpg"
                              alt="portfolio image"
                            />
                            <div className="item-title">
                              <Link to="#"> netharlands </Link>
                              <p>
                                <span>14 tours</span>
                                <span>12 places</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="filtr-item">
                            <img
                              src="assets/images/g6.jpg"
                              alt="portfolio image"
                            />
                            <div className="item-title">
                              <Link to="#"> turkey </Link>
                              <p>
                                <span>14 tours</span>
                                <span>6 places</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    {/* Gallery end */}

    {/* Blog Start */}
    <section id="blog" className="blog">
      <div className="container">
        <div className="blog-details">
          <div className="gallary-header text-center">
            <h2>Latest News & Blogs</h2>
            <p>Travel News from all over the world</p>
          </div>
          <div className="blog-content">
            <div className="row">
              <div className="col-sm-4 col-md-4">
                <div className="thumbnail">
                  <h2>trending news <span>15 november 2017</span></h2>
                  <div className="thumbnail-img">
                    <img src="assets/images/b1.jpg" alt="blog-img" />
                    <div className="thumbnail-img-overlay"></div>
                  </div>

                  <div className="caption">
                    <div className="blog-txt">
                      <h3>
                        <Link to="/blogDetails">
                          Travel Hacks for Saving Money on Your Next Adventure
                        </Link>
                      </h3>
                      <p>
                        Let's explore clever hacks to save money on your next trip! Tips for finding budget-friendly flights, scoring deals on accommodation, and traveling like a pro without sacrificing the adventure!
                      </p>
                      <Link to="/blogDetails">Read More</Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 col-md-4">
                <div className="thumbnail">
                  <h2>trending news <span>15 november 2017</span></h2>
                  <div className="thumbnail-img">
                    <img src="assets/images/b2.jpg" alt="blog-img" />
                    <div className="thumbnail-img-overlay"></div>
                  </div>
                  <div className="caption">
                    <div className="blog-txt">
                      <h3>
                        <Link to="/blogDetails">
                          The Ultimate Guide to Packing Light for Your Trip
                        </Link>
                      </h3>
                      <p>
                        Ditch the baggage blues! Learn how to travel smarter, not heavier. From choosing versatile clothing to mastering space-saving techniques, this guide will help you pack like a pro and maximize your travel tales!
                      </p>
                      <Link to="/blogDetails">Read More</Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 col-md-4">
                <div className="thumbnail">
                  <h2>trending news <span>15 november 2017</span></h2>
                  <div className="thumbnail-img">
                    <img src="assets/images/b3.jpg" alt="blog-img" />
                    <div className="thumbnail-img-overlay"></div>
                  </div>
                  <div className="caption">
                    <div className="blog-txt">
                      <h3>
                        <Link to="/blogDetails"
                          >Must-Have Travel Apps for a Smooth and Connected Journey
                          </Link>
                      </h3>
                      <p>
                        Never get lost, stressed, or disconnected again! This guide reveals the must-have travel apps for a smooth and connected journey. 
                      </p>
                      <Link to="/blogDetails">Read More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Blog End */}
    {/* Booking Section End */}

    </>
  )
}

export default about