import React, {useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Feedback } from "../../config/constants";
import { Post } from "../../config/api/post";
import swal from "sweetalert";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Post(Feedback.feedback, formData)
      .then(response => {
        swal({
          title: "Success",
          text: response?.message || "Your message has been sent successfully!",
          icon: "success",
          button: "OK"
        });
        setFormData({
          firstname: '',
          lastname: '',
          email: '',
          subject: '',
          message: ''
        });
      })
      .catch(error => {
        swal({
          title: "Error",
          text: "Submission failed. Please try again later.",
          icon: "error",
          button: "OK"
        });
        console.error('There was an error!', error);
      });
  };
  // const [formData, setFormData] = useState({
  //   firstname: '',
  //   lastname: '',
  //   email: '',
  //   subject: '',
  //   message: ''
  // });
  // const [contact, setContact] = useState('');

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.id]: e.target.value });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   Post(Feedback.feedback, formData)
  //     .then(response => {
  //       console.log(response)
  //       setContact(response?.message);
  //       setFormData({
  //         firstname: '',
  //         lastname: '',
  //         email: '',
  //         subject: '',
  //         message: ''
  //       });
  //     })
  //     .catch(error => {
  //       setContact('Submission failed. Please try again later.');
  //       console.error('There was an error!', error);
  //     });
  // };
  // const [formData, setFormData] = useState({
  //   firstname: '',
  //   lastname: '',
  //   email: '',
  //   subject: '',
  //   message: ''
  // });
  // const [feedback, setFeedback] = useState('');

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.id]: e.target.value });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   Post('', formData)
  //     .then(response => {
  //       setFeedback(response.data.message);
  //       setFormData({
  //         firstname: '',
  //         lastname: '',
  //         email: '',
  //         subject: '',
  //         message: ''
  //       });
  //     })
  //     .catch(error => {
  //       setFeedback('Submission failed. Please try again later.');
  //       console.error('There was an error!', error);
  //     });
  // };
  return (
    <>
      {/* about-us start */}
      <section id="home" className="about-us inner">
        <div className="container">
          <div className="about-us-content">
            <div className="row">
              <div className="col-sm-12">
                <div className="single-about-us">
                  <div className="about-us-txt">
                    <h2>Contact US</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-0">
                <div className="single-about-us"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-us end */}

      {/* Booking Section Start */}
      <div id="booking" className="bg-gray-100 py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            {/* <h2 className="text-3xl font-semibold mb-4">Contact Us</h2> */}
            <h2 className="text-xl text-gray-600 mb-6">
              Get in Touch with TravelFlyHub
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed mb-8">
              Thank you for your interest in TravelFlyHub! We're here to assist
              you with any inquiries, feedback, or assistance you may need.
              Please feel free to reach out to us using the contact details
              below or by filling out the contact form. We look forward to
              hearing from you and helping you make your travel plans seamless
              and enjoyable.
            </p>
          </div>
         
          <div className="bg-white shadow-md rounded-lg p-8 max-w-3xl mx-auto">
            <form onSubmit={handleSubmit} name="sentMessage" id="bookingForm" noValidate>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="control-group">
                  <label htmlFor="fname" className="block text-gray-700">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-input w-full border border-gray-300 px-2 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="firstname"
                    placeholder="E.g. John"
                    value={formData.firstname}
                    onChange={handleChange}
                    required
                    data-validation-required-message="Please enter first name"
                  />
                  <p className="help-block text-danger text-red-500 mt-1"></p>
                </div>
                <div className="control-group">
                  <label htmlFor="lname" className="block text-gray-700">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-input w-full border border-gray-300 px-2 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="lastname"
                    placeholder="E.g. Sina"
                    value={formData.lastname}
                    onChange={handleChange}
                    required
                    data-validation-required-message="Please enter last name"
                  />
                  <p className="help-block text-danger text-red-500 mt-1"></p>
                </div>
                <div className="control-group">
                  <label htmlFor="email" className="block text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-input w-full border border-gray-300 px-2 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="email"
                    placeholder="E.g. email@example.com"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    data-validation-required-message="Please enter your email"
                  />
                  <p className="help-block text-danger text-red-500 mt-1"></p>
                </div>
                <div className="control-group">
                  <label htmlFor="mobile" className="block text-gray-700">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-input w-full border border-gray-300 px-2 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="subject"
                    placeholder="E.g. Feedback Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    data-validation-required-message="Please enter your mobile number"
                  />
                  <p className="help-block text-danger text-red-500 mt-1"></p>
                </div>     
              </div>
              <div className="form-row">
                <div className="control-group w-full">
                  <label htmlFor="message" className="block text-gray-700">
                    Message
                  </label>
                  <textarea
                    id="message"
                    className="form-textarea w-full border border-gray-300 px-2 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    cols="30"
                    rows="5"
                    placeholder="Enter your message here"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger text-red-500 mt-1"></p>
                </div>
              </div>
              <div className="text-center mt-6">
                <button
                  type="submit"
                  className="about-view packages-btn"
                  id="bookingButton"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <div id="booking">
      <div className="container">
        <div className="section-header">
          <h2>Contact Us</h2>
          <h4>Get in Touch with TravelFlyHub</h4>
          <p>
          Thank you for your interest in TravelFlyHub! We're here to assist you with any inquiries, feedback, or assistance you may need. Please feel free to reach out to us using the contact details below or by filling out the contact form. We look forward to hearing from you and helping you make your travel plans seamless and enjoyable.
          </p>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="booking-form">
              <div id="success"></div>
              <form name="sentMessage" id="bookingForm" novalidate="novalidate">
                <div className="form-row row">
                  <div className="control-group col-md-6">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fname"
                      placeholder="E.g. John"
                      required="required"
                      data-validation-required-message="Please enter first name"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="control-group col-md-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lname"
                      placeholder="E.g. Sina"
                      required="required"
                      data-validation-required-message="Please enter last name"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="form-row row">
                  <div className="control-group col-md-6">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      placeholder="E.g. +1 234 567 8900"
                      required="required"
                      data-validation-required-message="Please enter your mobile number"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="control-group col-md-6">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="E.g. email@example.com"
                      required="required"
                      data-validation-required-message="Please enter your email"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="form-row row">
                  <div className="control-group col-sm-12">
                    <label>Message</label>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                </div>
                <div className="button col-sm-12">
                  <button
                    type="submit"
                    className="about-view packages-btn"
                    id="bookingButton"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        
      </div>
    </div> */}
      {/* Booking Section End  */}
    </>
  );
};

export default Contact;
