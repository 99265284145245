import React, { useState, useEffect } from "react";
import { RiTwitterFill } from "react-icons/ri";
import { FaInstagram, FaFacebookF, FaComments } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../config/functions";
// import { RiTwitterFill } from 'react-icons/ri';
import { FaAngleDoubleUp } from "react-icons/fa";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // const scrollToTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  const openTawkToChat = () => {
    if (window.Tawk_API) {
      window.Tawk_API.maximize();
    }
  };

  return (
    <>
      {/* footer-copyright start  */}
      <footer class="footer-copyright">
        <div class="container">
          <div class="footer-content">
            <div class="row">
              <div class="col-sm-6 col-md-4">
                <div class="single-footer-item">
                  <div class="footer-logo mb-2">
                    <Link to="/">
                      <img src={ImageUrl("footer-logo.png")} />
                    </Link>
                  </div>
                  <p className="my-4">
                    At TravelFly Hub, we believe that travel is not just about
                    reaching a destination; it’s about the journey, the
                    experiences, and the memories you create along the way.
                  </p>
                  <div class="footer-logo mt-2">
                    <Link to="/">
                      <img src={ImageUrl("payment.png")} />
                    </Link>
                  </div>
                </div>
              </div>

              <div class="col-sm-2 col-md-1"></div>

              <div class="col-sm-4 col-md-3">
                <div class="single-footer-item my-6">
                  <h2 className="text-left">Quick Links</h2>
                  <div class="single-footer-txt py-4">
                    <p className="pb-2">
                      <Link to="/" className="navLinkOne">
                        Home
                      </Link>
                    </p>
                    <p className="pb-2">
                      <Link to="/about" className="navLinkOne">
                        About
                      </Link>
                    </p>
                    <p className="pb-2">
                      <Link to="/blog" className="navLinkOne">
                        Blog
                      </Link>
                    </p>
                    <p>
                      <Link to="/contactus" className="navLinkOne">
                        Contact
                      </Link>
                    </p>
                    <p>
                      <Link to="/terms" className="navLinkOne">
                        Terms And Conditions
                      </Link>
                    </p>
                    <p>
                      <Link to="/privacy" className="navLinkOne">
                        Privacy Policy
                      </Link>
                    </p>
                  </div>
                </div>
              </div>

              {/* <div class="col-sm-3">
              <div class="single-footer-item">
                <h2>popular destination</h2>
                <div class="single-footer-txt">
                  <p><a href="#">china</a></p>
                  <p><a href="#">venezuela</a></p>
                  <p><a href="#">brazil</a></p>
                  <p><a href="#">australia</a></p>
                  <p><a href="#">london</a></p>
                </div>
              </div>
            </div> */}

              <div className="col-sm-12 col-md-4">
                <div className="single-footer-item text-center my-6">
                  <h2 className="text-left">Contact Us</h2>
                  <div className="single-footer-txt text-left">
                    <div className="flex items-start">
                      <FaPhoneAlt className="text-black mb-1 mr-3" />
                      <div className="mt-4">
                        <p className="text-lg text-black font-semibold">
                          Contact Number
                        </p>
                        <p className="text-md">3213689275</p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <FaEnvelope className="text-black mb-1 mr-3" />
                      <div className="mt-4">
                        <p className="text-lg text-black font-semibold">
                          Email
                        </p>
                        <p className="text-md text-lowercase">
                          <a
                            href="mailto:info@travelflyhub.com"
                            className="text-md text-lowercase text-black hover:underline"
                          >
                            info@travelflyhub.com
                          </a>
                          {/* info@travelflyhub.com */}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <FaMapMarkerAlt
                        size={18}
                        className="text-black mb-1 mr-3"
                      />
                      <div className="mt-4">
                        <p className="text-lg text-black font-semibold">
                          Address
                        </p>
                        <p className="text-md">North Warner Park 336/A</p>
                        <p className="text-md">New York, USA</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="col-sm-4">
                <div class="single-footer-item text-center">
                  <h2 class="text-left">Contact Us</h2>
                  <div class="single-footer-txt text-left">
                    <p>3213689275</p>
                    <p>
                      
                        info@travelflyhub.com
                    
                    </p>
                    <p>North Warnner Park 336/A</p>
                    <p>Newyork, USA</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <hr />
          <div class="foot-icons">
            <ul class="footer-social-links list-inline list-unstyled">
              <li my-4>
                <Link to="#" target="_blank" className="foot-icon-bg-1 lin">
                  <FaFacebookF />
                </Link>
              </li>
              <li>
                <Link to="#" target="_blank" className="foot-icon-bg-2 lin">
                  <RiTwitterFill />
                </Link>
              </li>
              <li>
                <Link to="#" target="_blank" className="foot-icon-bg-3 lin">
                  <FaInstagram />
                </Link>
              </li>
            </ul>
            <p>
              &copy; 2024 <Link to="/">Travel fly Hub</Link>. All Right Reserved
            </p>
          </div>
          <div className="flex justify-center space-x-4 mt-4">
            <button
              // className="bg-blue-600 text-white px-4 py-3 rounded-full flex items-center space-x-2 hover:bg-blue-700"
              onClick={openTawkToChat}
            >
              {/* <FaComments className="text-xl" /> */}
              {/* <span>Chat</span> */}
              {/* <FaAngleDoubleUp className="text-xl" /> */}
            </button>
          </div>
          {/* <div id="scroll-Top">
          <i
            class="fa fa-angle-double-up return-to-top"
            id="scroll-top"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title="Back to Top"
            aria-hidden="true"
          ></i>
        </div> */}
        </div>
      </footer>

      {/* footer-copyright end  */}
      {isVisible && (
        <div
          className="fixed bottom-20 right-4 bg-[#49949C] text-white p-3 rounded-full cursor-pointer"
          onClick={scrollToTop}
        >
          <FaAngleDoubleUp className="text-xl" />
        </div>
      )}
    </>
  );
};

export default Footer;
