import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ClientLayout from "../../components/layout";
import Home from "../../views/home";
import About from "../../views/about";
import Blogs from "../../views/blog";
import BlogDetails from "../../views/blog/blogDetails";
import Hotels from "../../views/hotel";
import HotelDetails from "../../views/hotel/hotelDetails";
import Flights from "../../views/flight";
import FlightDetails from "../../views/flight/flightDetails";
import ContactUs from "../../views/contactUs";
import Booking from "../../views/booking";
import CarRentals from "../../views/carRental";
import CarRentalDetails from "../../views/carRental/carRentalDetails";
import TermsAndConditions from "../../views/termsandconditions";
import PrivacyPolicy from "../../views/privacypolicy";

// basename={process.env.PUBLIC_URL}
function MyRouter() {
  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route
            path="/"
            index
            element={
              <ClientLayout>
                <Home />
              </ClientLayout>
            }
          />
          <Route
            path="/about"
            element={
              <ClientLayout>
                <About />
              </ClientLayout>
            }
          />
          <Route
            path="/blog"
            element={
              <ClientLayout>
                <Blogs />
              </ClientLayout>
            }
          />
          <Route
            path="/blogDetails"
            element={
              <ClientLayout >
                <BlogDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/hotel"
            element={
              <ClientLayout>
                <Hotels />
              </ClientLayout>
            }
          />
          <Route
            path="/hotelDetails"
            element={
              <ClientLayout >
                <HotelDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/flight"
            element={
              <ClientLayout>
                <Flights />
              </ClientLayout>
            }
          />
          <Route
            path="/flightDetails"
            element={
              <ClientLayout >
                <FlightDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/carRental"
            element={
              <ClientLayout>
                <CarRentals />
              </ClientLayout>
            }
          />
          <Route
            path="/carRentalDetails"
            element={
              <ClientLayout >
                <CarRentalDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/contactus"
            element={
              <ClientLayout>
                <ContactUs />
              </ClientLayout>
            }
          />
          <Route
            path="/terms"
            element={
              <ClientLayout>
                <TermsAndConditions />
              </ClientLayout>
            }
          />
          <Route
            path="/privacy"
            element={
              <ClientLayout>
                <PrivacyPolicy />
              </ClientLayout>
            }
          />
          <Route
            path="/booking"
            element={
              <ClientLayout>
                <Booking />
              </ClientLayout>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default MyRouter;