import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import dayjs from "dayjs";

const Flight = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const flightOffers = location?.state?.flightOffers;
  const aggregation = location?.state?.aggregation;

  useEffect(() => {
    // Simulating data fetching for demonstration. Remove this if data is fetched via API
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust timeout as needed
  }, []);

  const handleReadMore = (flightToken) => {
    navigate("/flightDetails", {
      state: {
        token: flightToken,
      },
    });
  };

  if (!flightOffers || !aggregation || (Array.isArray(flightOffers) && flightOffers.length === 0)) {
    // Handle case where flight data is null or an empty array
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', color: "#EF4444"}}>
        No Flights Available!
      </div>
    );
  }
  

  // if (!flightOffers || !aggregation) {
  //   // Handle case where flight data is null
  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  //       No flights data available
  //     </div>
  //   );
  // }

  return (
    <>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <ReactLoading type="spin" color="#49949C" height={100} width={100} />
        </div>
      ) : (
        <>
          {/* about-us start */}
          <section id="home" className="about-us inner">
            <div className="container">
              <div className="about-us-content">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="single-about-us">
                      <div className="about-us-txt">
                        <h2>List Of All Flights</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-0">
                    <div className="single-about-us"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* about-us end */}

          {/* blog start */}
          <section id="pack" className="packages">
            <div className="container">
              <div className="gallary-header text-center">
                <h2>Find Your Perfect Flight</h2>
                <p>Explore our unforgettable flights for your dream trips!</p>
              </div>
              <div className="packages-content">
                {aggregation.airlines.map((airline, airlineIndex) => (
                  <div className="row" key={airlineIndex}>
                    {flightOffers.map((flight, flightIndex) =>
                      flight.segments.map((segment, segmentIndex) =>
                        segment.legs.map((leg, legIndex) => (
                          <div key={legIndex} className="col-md-4 col-sm-6">
                            <div className="single-package-item">
                              <div className="single-package-item-txt">
                                <h3>
                                  {airline.name}{" "}
                                  <span className="pull-right">
                                    {airline.minPrice.currencyCode}{" "}
                                    {airline.minPrice.units}
                                  </span>
                                </h3>
                                <div className="packages-para">
                                  <p>
                                    <i className="fa fa-angle-right"></i> Arrival:{" "}
                                    {leg.arrivalAirport.cityName} (
                                    {leg.arrivalAirport.code}) at {dayjs(leg.arrivalTime).format("DD/MM/YYYY hh:mm A")}
                                  </p>
                                  <p>
                                    <i className="fa fa-angle-right"></i> Departure:{" "}
                                    {leg.departureAirport.cityName} (
                                    {leg.departureAirport.code}) at{" "}
                                    {dayjs(leg.departureTime).format("DD/MM/YYYY hh:mm A")}
                                  </p>
                                  <p>
                                    <i className="fa fa-angle-right"></i> Cabin Class:{" "}
                                    {leg.cabinClass}
                                  </p>
                                  <div className="about-btn">
                                    <button
                                      className="about-view packages-btn"
                                      onClick={() => handleReadMore(flight?.token)}
                                    >
                                      Read More
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )
                    )}
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Flight;





//Old Code
// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";

// const Flight = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   console.log(location, "location");
//   const flightOffers = location?.state.flightOffers;
//   const aggregation = location?.state?.aggregation;
//   console.log(flightOffers, "flights");

//   if (!flightOffers || !aggregation) {
//     // Handle case where hotels data is null
//     return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No flights data available</div>;
//   }

//   const handleReadMore = (flightToken) => {
//     console.log(flightToken, "flighttoken")
//     navigate("/flightDetails", {
//       state: {
//         token: flightToken,
//       },
//     });
//   };

//   return (
//     <>
//       {/* about-us start */}
//       <section id="home" className="about-us inner">
//         <div className="container">
//           <div className="about-us-content">
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className="single-about-us">
//                   <div className="about-us-txt">
//                     <h2>List Of All Flights</h2>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-sm-0">
//                 <div className="single-about-us"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* about-us end */}

//       {/* blog start */}

//       <section id="pack" className="packages">
//         <div className="container">
//           <div className="gallary-header text-center">
//             <h2>Find Your Perfect Flight</h2>
//             <p>Explore our unforgettable flights for your dream trips!</p>
//           </div>
//           <div className="packages-content">
//             {aggregation.airlines.map((airline, airlineIndex) => (
//               <div className="row">
//                 {flightOffers.map((flight, flightIndex) =>
//                   flight.segments.map((segment, segmentIndex) =>
//                     segment.legs.map((leg, legIndex) => (
//                       <div key={airlineIndex} className="col-md-4 col-sm-6">
//                         <div className="single-package-item">
//                           <div
//                             className="single-package-item-txt"
//                             key={legIndex}
//                           >
//                             <h3>
//                               {airline.name}{" "}
//                               <span className="pull-right">
//                                 {airline.minPrice.currencyCode}{" "}
//                                 {airline.minPrice.units}
//                               </span>
//                             </h3>
//                             <div className="packages-para">
//                               <p>
//                                 <i className="fa fa-angle-right"></i> Arrival:{" "}
//                                 {leg.arrivalAirport.cityName} (
//                                 {leg.arrivalAirport.code}) at {leg.arrivalTime}
//                               </p>
//                               <p>
//                                 <i className="fa fa-angle-right"></i> Departure:{" "}
//                                 {leg.departureAirport.cityName} (
//                                 {leg.departureAirport.code}) at{" "}
//                                 {leg.departureTime}
//                               </p>
//                               <p>
//                                 <i className="fa fa-angle-right"></i> Cabin
//                                 Class: {leg.cabinClass}
//                               </p>
//                               <div className="about-btn">
//                                 <button
//                                   className="about-view packages-btn"
//                                   onClick={() =>
//                                     handleReadMore(flight?.token)
//                                   }
//                                 >
//                                   Read More
//                                 </button>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     ))
//                   )
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Flight;
