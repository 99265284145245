import React, { useState } from 'react';
import { MdOutlineTravelExplore } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import { Link } from 'react-router-dom';
import { ImageUrl } from "../../config/functions";

const Header = () => {
    const [active, setActive] = useState('navBar');
    
    const showNav = () => {
        setActive('navBar activeNavbar');
    }
    
    const removeNav = () => {
        setActive('navBar');
    }
    
    return (
        <section className='navBarSection'>
            <header className='header flex'>
                <div className='logoDiv'>
                    <Link to='/' className='logo flex'>
                        <img src={ImageUrl("logo.png")} className='image' alt="Logo"/>
                    </Link>
                </div>
                <div className={active}>
                    <ul className='navLists flex'>
                        <li className='navItem'>
                          <Link to='/' className='navLink'>Home</Link>
                        </li>
                        <li className='navItem'>
                          <Link to='/about' className='navLink'>About</Link>
                        </li>
                        {/* <li className='navItem'>
                          <a href='#pack' className='navLink'>Packages</a>
                        </li>
                        <li className='navItem'>
                          <a href='#spo' className='navLink'>Special Offers</a>
                        </li> */}
                        <li className='navItem'>
                            <Link to='/blog' className='navLink'>Blog</Link>
                        </li>
                        {/* <li className='navItem'>
                          <a href='#subs' className='navLink'>Subscription</a>
                        </li> */}
                        <li className='navItem'>
                            <Link to='/contactus' className='navLink'>Contact Us</Link>
                        </li>
                        {/* <button className='book-btn'>
                            <Link to='/booking' className='navLink2'>Book Now</Link>
                        </button> */}
                    </ul>
                    <div onClick={removeNav} className='closeNavbar'>
                        <AiFillCloseCircle className="icon"/>
                    </div>
                </div>
                <div onClick={showNav} className='toggleNavbar'>
                    <TbGridDots className='icon'/>
                </div>
            </header>
        </section>
    );
}

export default Header;
