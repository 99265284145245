import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const BlogDetails = () => {
  const location = useLocation();
  const { image, details } = location.state || {};
  console.log(image, details, "locationState");

  const renderDetails = (details) => {
    switch (details) {
      case "details1":
        return (
          <>
            <h2>Travel Hacks for Saving Money on Your Upcoming Trip</h2>
            <p>
              Everyone deserves a good retreat from the complexities of life
              every once in a while. However, due to the heavy price tags that
              come with traveling, many of us have to compromise on our travel
              desires. But there are some money-saving ways that can help you
              plan the perfect vacation on your budget.
            </p>
            <p>
              So, let’s take a look at the best travel hacks that help you save
              big on your next vacation trip!
            </p>
            <h4>How Do I Save on Flights?</h4>
            <p>
              Plane tickets make up a huge chunk of your total travel expenses.
              However, there are some smart ways through which you can get some
              snag deals. Here’s what you need to do:
            </p>
            <h5>Flexibility is King</h5>
            <p>
              We all know that demand does influence flight prices. This is why
              you need to be flexible with your travel dates and times so that
              you can choose cheaper flight fares. A golden rule here is to go
              for weekdays and off-peak hours to get potential lower-priced
              flight tickets. Besides, you can check for airports with extra
              travel times at the beginning or the end of your flight. Usually,
              these flights are cheaper than the standard ones.
            </p>
            <h5>Consider Alternative Airports</h5>
            <p>
              Another thing that you can do to save on flight prices is to look
              for alternative, smaller airports. These airports can offer you
              much more competitive flight rates and have good, shorter flight
              options. However, you should be mindful of their add-on costs and
              baggage fees.
            </p>
            <p>
              The right way to look for alternative airports and compare them is
              to use comparison websites like Google Flights and Travel Fly Hub.
              These resources help you find airports that are nearer to your
              travel destination and offer better flight rates.
            </p>
            <h4>How to Find Affordable Accommodations?</h4>
            <p>
              Hotel bills can exhaust your travel budget in no time. However,
              there are a variety of other accommodations you can choose to make
              your vacations fun yet cost-saving. Here’s how you can find a good
              accommodation:
            </p>
            <h5>Consider Alternatives of Hotels</h5>
            <p>
              Hotels are the most popular accommodation choice, but they are not
              pocket-friendly. That’s why you need to look for alternatives like
              hostels. Hostels are much cheaper compared to hotels and can offer
              you dorm rooms or private rooms for a stay.
            </p>
            <p>
              Besides, you can look for vacation rentals such as Airbnb
              apartments or homestays. These options are not only cheap but also
              let you enjoy a fun local experience. Along with that, it gives
              you amenities like a personal kitchen, so you can also save on
              meals.
            </p>
            <h5>Don’t Go for Expensive Spots</h5>
            <p>
              Another thing you can do to cut the accommodation prices is to opt
              for cheaper locations. Most people make the mistake of choosing
              city centers, thinking about the convenience. However, these
              locations are generally much more expensive. Instead, what you can
              do is opt for slightly outside locations that offer convenient
              transport services.
            </p>
            <h4>How can I Stretch My Food Budget?</h4>
            <p>
              Your travel experience is incomplete if you do not try good food
              from your destination. That’s why you must have a separate food
              budget when on vacation. Here’s how you can stretch your food
              budget on vacations:
            </p>
            <ol>
              <li>
                1. Pack reusable drinkware and on-the-go snacks so you can avoid
                overpriced tourist trap purchases.
              </li>
              <li>
                2. Prepare your own meals by getting ingredients from local
                grocery stores and farmers’ markets.
              </li>
              <li>
                3. Avoid tourist hotspots and visit local restaurants and cafes
                for an authentic experience.
              </li>
            </ol>
            <h5>Final Thoughts</h5>
            <p>
              Here, we have shared with you some smart travel hacks that can
              help you save travel expenses and freely enjoy your vacations. So,
              before packing your bags for the next trip, make sure you are to
              find cheaper flight options and good alternatives to hotels and go
              for local food instead of expensive cafes. With these smart hacks,
              you can enjoy a budget-savvy travel experience.
            </p>
          </>
        );
      case "details2":
        return (
          <>
            <h2>The Ultimate Guide to Packing Light for Your Trip</h2>
            <p>
              Traveling is an experience that leaves your mind and body feeling free from all the burdens. But you might not be able to feel that relaxation if you keep on loading your luggage with unnecessary items. Many of us make the mistake of adding multiple items of the same category with the thought that we might need them on the trip. However, you do not even get to use most of these things.
            </p>
            <p>That’s why packing light is the way to go if you want to have a carefree travel experience. So, let’s help you pack smartly for a happy, relaxed, and lightweight trip!</p>
            <h5>Choosing the Right Luggage</h5>
            <p>One of the most important things that you need for smart packing is good luggage so you can easily carry it around. A convenient choice is to get a carry-on bag that gives you good maneuverability and helps you avoid baggage claims. You can get a carry-on that falls within the airline's restrictions.</p>
            <p>On the other hand, you can also go for a versatile backpack. It is helpful for fitting in your gadgets, passports and tickets, and all other items you would be needing quickly during the travel.</p>
            <p>Other than that, a good quality suitcase is a must if you are trip is more than a week long. Pick a medium-sized suitcase that you can easily drag around and keep all your travel essentials in.</p>
            <h5>Planning for Less</h5>
            <p>Traveling with heavy luggage means more struggle at crowded airports. You have to drag heavy bags and suitcases and wait for long hours at baggage claim lines. On top of that, you also have to pay additional luggage fees that constraints your travel budget.</p>
            <p>So, if you want to avoid this struggle and enjoy your vacations carefreely, you need to think like a minimalist. You need to get rid of that “just in case” mindset and only pack what you know you will certainly need.</p>
            <ul>
              <li>Pack mix and match outfits</li>
              <li>Do mid-trip laundry</li>
              <li>Do not pack too many accessories</li>
              <li>Remember that one week of clothing is usually enough</li>
            </ul>
            <h5>Use the 1-2-3-4-5-6 Packing Rule</h5>
            <p>The 1-2-3-4-5-6 rule is the golden principle for packing light. This rule says that even if you are traveling for over two weeks, you do not need to grab everything in your closet. Instead, what you can do is pack things in the following order:</p>
            <ul>
              <li>1. One headwear</li>
              <li>2. Two pairs of shoes</li>
              <li>3. Three bottoms</li>
              <li>4. Four tops</li>
              <li>5. Five pairs of socks</li>
              <li>6. Six pairs of undergarments</li>
            </ul>
            <p>With these things, you can create a capsule wardrobe and wear them by mixing and matching. This rule gives you a proper packing plan and keeps your luggage light.</p>
            <h5>Final Thoughts: No Place for ‘Maybes’</h5>
            <p>
            If you want to travel without thinking too much about your luggage, you need to say no to your ‘maybe’ pile. There is absolutely no need to grab everything you might need on the trip. Instead, go for a minimalist approach and pack like you are traveling for a week. Make a packing list based on the 1-2-3-4-5-6 rule and stick to it religiously.
            </p>
          </>
        );
      case "details3":
        return (
          <>
            <h2>Must-Have Travel Apps for a Smooth and Connected Trip</h2>
            <p>
            Planning your vacations and exploring new places excite you like nothing else. But it comes with its own anxieties, packing, booking flights, airport check-ins, as well as finding the right places to stay. All of these feel painstakingly difficult, especially if you’re a newbie traveler.
            </p>
            <p>However, there are now many interactive apps and online resources that can make your vacations much smoother. Be it packing, bookings, or navigating through a new city, these apps can simplify the processes for you so you enjoy trips carefreely.</p>
            <p>Let’s take a look at some of the useful apps you must have on your phone to enjoy a smooth and connected trip.</p>
            <h5>Best Travel Apps to Have on Your Phone</h5>
            <ol>
              <li><strong>1. Google Translate </strong>If you are traveling to a new city and you don’t understand its local language, Google Translate becomes an indispensable companion for you. You simply can’t live in an unknown location without it. Using Google Translate, you can easily communicate with the locals. It makes your people dealing quite easy, and you won’t have to play the guessing game to understand what people are trying to say.</li>
              <li><strong>2. Travel Fly Hub </strong>Travel Fly Hub is a complete resource for planning your trips. It gives you information about flights, accommodations, and ride rentals. You can easily compare different ticket rates and get the best deals for you. Besides, you can also effortlessly plan a customized tour by finding great hotel destinations around the world. So, explore its director to find the best destinations with the best deals.</li>
              <li><strong>3. PackPoint </strong>PackPoint is one of the best apps that can make the whole packing procedure much easier and quicker. The app can provide you with the most accurate list to make sure you do not miss out on any travel essentials. You simply give the app information about your trip type and where and when you are traveling, and PackPoint instantly creates the list for you.</li>
              <li><strong>4. TripIt </strong>If you want to keep your trip organized throughout the timeline, TripIt is the best way to do that. It offers you a complete itinerary plan, so you always know your next move and make it to flights and other bookings on time. All you need to do to create an itinerary for your next trip is to feed all the information about your booking to TripIt, and it will make the complete timeline for you.</li>
              <li><strong>5. Google Maps </strong>Google Maps is a life-savor on trips. If you are traveling for the first time to an unfamiliar place without a plan, it’s so easy to get lost. However, Google Maps is an app that always has your back no matter what. It can give you information about restaurants, metro stations, grocery stores, spas, bars, and other necessary facilities that you may need during your travels. So, when you use Google Maps, finding your destinations becomes much more convenient.</li>
              <li><strong>6. Uber or Any other Ride App </strong>Traveling without a ride app, such as Uber, has become impossible. Uber is one of the most popular ride apps around the world, and it makes traveling from one place to another quite convenient. You might be tempted to use the public metro to reach your destinations, but it isn’t likely that your schedule matches the metro timings. That’s why you need to have Uber or a similar app on your phone when traveling.</li>
              <li><strong>7. GasBuddy </strong>If you are traveling in your own vehicle or renting one for a road trip, you need to know the gas station locations. GasBuddy is the finest app for finding gas station locations near you. The app needs data as it runs in real-time to give you information about gas station locations along with prices.</li>
            </ol>
            <h5>Final Thoughts</h5>
            <p>Traveling should be a refreshing experience for you. But if you keep paying attention to organization and bookings, you will never be able to enjoy your vacations. However, there are many different apps now that help you plan the perfect trip so you do not get into any hassle mid-trip. Get all these apps and make your next trips smoother and more connected!</p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* about-us start */}
      <section id="home" className="about-us inner">
        <div className="container">
          <div className="about-us-content">
            <div className="row">
              <div className="col-sm-12">
                <div className="single-about-us">
                  <div className="about-us-txt">
                    <h2>Blog Details</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-0">
                <div className="single-about-us"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-us end */}

      {/* Booking Section Start */}
      <div id="booking">
        <div className="container">
          <div className="section-header">
            {image && (
              <img
                src={image}
                className="blog-details-image"
                alt="Blog Details"
              />
            )}
            {renderDetails(details)}
            {/* <h2>trending news</h2>
            <p>
              Europe is filled with well-known tourist spots, but in this
              article, we uncover the lesser-known destinations that offer
              unique experiences away from the crowds. Discover charming
              villages, stunning landscapes, and cultural treasures that are off
              the beaten path.
            </p>
            <p>
              Backpacking across South America can be the adventure of a
              lifetime. Our comprehensive guide covers everything you need to
              know, from packing essentials to safety tips and must-visit spots
              in countries like Brazil, Argentina, and Peru.
            </p>
            <p>
              The United States offers some of the most scenic and diverse road
              trip routes in the world. From the coastal highways of California
              to the historic trails of the East Coast, we detail the best
              routes, attractions along the way, and tips for a smooth journey.
            </p>
            <p>
              Traveling doesn’t have to break the bank. In this article, we
              share practical tips on how to save money on flights,
              accommodation, food, and activities without compromising on the
              experience. Learn how to make the most of your travel budget.
            </p>
            <p>
              Asia is a continent rich in culture and history. We delve into the
              top cultural attractions, festivals, and traditions you should
              experience. From the temples of Thailand to the cherry blossoms in
              Japan, discover the wonders of Asia.
            </p> */}
          </div>
        </div>
      </div>
      {/* Booking Section End */}

      {/* blog start */}
      <section id="blog" className="blog pt-0">
        <div className="container">
          <div className="blog-details">
            <div className="blog-content">
              <div className="row">
                {[
                  {
                    id: 1,
                    date: "15 november 2017",
                    image: "assets/images/b11.jpg",
                    title:
                      "Travel Hacks for Saving Money on Your Next Adventure",
                    description:
                      "Let's explore clever hacks to save money on your next trip! Tips for finding budget-friendly flights, scoring deals on accommodation, and traveling like a pro without sacrificing the adventure!",
                  },
                  {
                    id: 2,
                    date: "15 november 2017",
                    image: "assets/images/b12.jpg",
                    title: "The Ultimate Guide to Packing Light for Your Trip",
                    description:
                      "Ditch the baggage blues! Learn how to travel smarter, not heavier. From choosing versatile clothing to mastering space-saving techniques, this guide will help you pack like a pro and maximize your travel tales!",
                  },
                  {
                    id: 3,
                    date: "15 november 2017",
                    image: "assets/images/b13.jpg",
                    title:
                      "Must-Have Travel Apps for a Smooth and Connected Journey",
                    description:
                      "Never get lost, stressed, or disconnected again! This guide reveals the must-have travel apps for a smooth and connected journey.",
                  },
                ].map((blog, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="thumbnail">
                      <h2>
                        trending news <span>{blog.date}</span>
                      </h2>
                      <div className="thumbnail-img">
                        <img
                          src={
                            !blog ? "assets/images/blogdetails.png" : blog.image
                          }
                          alt="blog-img"
                        />
                        <div className="thumbnail-img-overlay"></div>
                      </div>
                      <div className="caption">
                        <div className="blog-txt">
                          <h3>
                            <Link>{blog.title}</Link>
                          </h3>
                          <p>{blog.description}</p>
                          <Link>Read More</Link>
                        </div>
                      </div>
                      {/* <div className="caption">
                        <div className="blog-txt">
                          <h3>
                            <button>{blog.title}</button>
                          </h3>
                          <p>{blog.description}</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* blog end */}
    </>
  );
};

export default BlogDetails;

// import React from "react";
// import { Link } from "react-router-dom";

// const blogDetails = () => {
//   return (
//     <>
//       {/* about-us end */}
//       <section id="home" className="about-us inner">
//         <div className="container">
//           <div className="about-us-content">
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className="single-about-us">
//                   <div className="about-us-txt">
//                     <h2>Blog Details</h2>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-sm-0">
//                 <div className="single-about-us"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* about-us end */}

//       {/* Booking Section Start */}
//       <div id="booking">
//         <div className="container">
//           <div className="section-header">
//             <img
//               src="assets/images/blogdetails.png"
//               className="blog-details-image"
//             />
//             <h2>trending news</h2>
//             <p>
//               Europe is filled with well-known tourist spots, but in this
//               article, we uncover the lesser-known destinations that offer
//               unique experiences away from the crowds. Discover charming
//               villages, stunning landscapes, and cultural treasures that are off
//               the beaten path.
//             </p>
//             <p>
//               Backpacking across South America can be the adventure of a
//               lifetime. Our comprehensive guide covers everything you need to
//               know, from packing essentials to safety tips and must-visit spots
//               in countries like Brazil, Argentina, and Peru.
//             </p>
//             <p>
//               The United States offers some of the most scenic and diverse road
//               trip routes in the world. From the coastal highways of California
//               to the historic trails of the East Coast, we detail the best
//               routes, attractions along the way, and tips for a smooth journey.
//             </p>
//             <p>
//               Traveling doesn’t have to break the bank. In this article, we
//               share practical tips on how to save money on flights,
//               accommodation, food, and activities without compromising on the
//               experience. Learn how to make the most of your travel budget.
//             </p>
//             <p>
//               Asia is a continent rich in culture and history. We delve into the
//               top cultural attractions, festivals, and traditions you should
//               experience. From the temples of Thailand to the cherry blossoms in
//               Japan, discover the wonders of Asia.
//             </p>
//           </div>
//         </div>
//       </div>

//       {/* blog start */}
//       <section id="blog" className="blog pt-0">
//         <div className="container">
//           <div className="blog-details">
//             <div className="blog-content">
//               <div className="row">
//                 <div className="col-sm-4 col-md-4">
//                   <div className="thumbnail">
//                     <h2>
//                       trending news <span>15 november 2017</span>
//                     </h2>
//                     <div className="thumbnail-img">
//                       <img src="assets/images/b1.jpg" alt="blog-img" />
//                       <div className="thumbnail-img-overlay"></div>
//                     </div>

//                     <div className="caption">
//                       <div className="blog-txt">
//                         <h3>
//                           <Link to="/blogDetails">
//                             Travel Hacks for Saving Money on Your Next Adventure
//                           </Link>
//                         </h3>
//                         <p>
//                           Let's explore clever hacks to save money on your next
//                           trip! Tips for finding budget-friendly flights,
//                           scoring deals on accommodation, and traveling like a
//                           pro without sacrificing the adventure!
//                         </p>
//                         <Link to="/blogDetails">Read More</Link>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-sm-4 col-md-4">
//                   <div className="thumbnail">
//                     <h2>
//                       trending news <span>15 november 2017</span>
//                     </h2>
//                     <div className="thumbnail-img">
//                       <img src="assets/images/b2.jpg" alt="blog-img" />
//                       <div className="thumbnail-img-overlay"></div>
//                     </div>
//                     <div className="caption">
//                       <div className="blog-txt">
//                         <h3>
//                           <Link to="/blogDetails">
//                             The Ultimate Guide to Packing Light for Your Trip
//                           </Link>
//                         </h3>
//                         <p>
//                           Ditch the baggage blues! Learn how to travel smarter,
//                           not heavier. From choosing versatile clothing to
//                           mastering space-saving techniques, this guide will
//                           help you pack like a pro and maximize your travel
//                           tales!
//                         </p>
//                         <Link to="/blogDetails">Read More</Link>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-sm-4 col-md-4">
//                   <div className="thumbnail">
//                     <h2>
//                       trending news <span>15 november 2017</span>
//                     </h2>
//                     <div className="thumbnail-img">
//                       <img src="assets/images/b3.jpg" alt="blog-img" />
//                       <div className="thumbnail-img-overlay"></div>
//                     </div>
//                     <div className="caption">
//                       <div className="blog-txt">
//                         <h3>
//                           <Link to="/blogDetails">
//                             Must-Have Travel Apps for a Smooth and Connected
//                             Journey
//                           </Link>
//                         </h3>
//                         <p>
//                           Never get lost, stressed, or disconnected again! This
//                           guide reveals the must-have travel apps for a smooth
//                           and connected journey.
//                         </p>
//                         <Link to="/blogDetails">Read More</Link>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* blog end */}
//       {/* Booking Section End */}
//     </>
//   );
// };

// export default blogDetails;
