import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import Loading from "react-loading";

const FlightDetails = () => {
  const location = useLocation();
  const [flightDetails, setFlightDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFlightDetails = async () => {
      const { token } = location.state;
      const options = {
        method: "GET",
        url: "https://booking-com15.p.rapidapi.com/api/v1/flights/getFlightDetails",
        params: { token },
        headers: {
          "x-rapidapi-key":
            "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
          "x-rapidapi-host": "booking-com15.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.request(options);
        console.log(response?.data);
        setFlightDetails(response?.data?.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchFlightDetails();
  }, [location.state]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Loading type="spin" color="#49949C" height={100} width={100} />
      </div>
    );
  }

  if (!flightDetails) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>No Flight details available</div>;
  }

  const { segments, tripType, travellerPrices, baggagePolicies, travellers } = flightDetails;

  return (
    <>
      <section id="home" className="about-us inner">
        <div className="container">
          <div className="about-us-content">
            <div className="row">
              <div className="col-sm-12">
                <div className="single-about-us">
                  <div className="about-us-txt">
                    <h2>Flight Details</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-0">
                <div className="single-about-us"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flight-details-section">
        <div className="flight-details-main">
          {segments?.length > 0 ? (
            segments.map((segment, index) => (
              <div key={index} className="flight-details-segment">
                <div className="flight-details-header">
                  <h3>Flight Information</h3>
                  <p>
                    Departure City: {segment.departureAirport.cityName} (
                    {segment.departureAirport.code})
                  </p>
                  <p>
                    Arrival City: {segment.arrivalAirport.cityName} (
                    {segment.arrivalAirport.code})
                  </p>
                  <p>
                    Departure Time:{" "}
                    {dayjs(segment.departureTime).format("DD/MM/YYYY hh:mm A")}
                  </p>
                  <p>
                    Arrival Time:{" "}
                    {dayjs(segment.arrivalTime).format("DD/MM/YYYY hh:mm A")}
                  </p>
                  <p>Trip Type: {tripType}</p>
                  <h4>Legs Information:</h4>
                  {segment.legs?.length > 0 &&
                    segment.legs.map((leg, legIndex) => (
                      <div key={legIndex} className="flight-details-leg">
                        <p>
                          <strong>Leg {legIndex + 1}:</strong>
                        </p>
                        <p>
                          Departure Airport Name: {leg.departureAirport.name}
                        </p>
                        <p>Arrival Airport Name: {leg.arrivalAirport.name}</p>
                        {/* <p>Departure Time: {leg.departureTime}</p> */}
                        <p>Flight No: {leg.flightInfo.flightNumber}</p>
                        <p>Cabin Class: {leg.cabinClass}</p>
                        <p>Airline: {leg.carriersData[0].name}</p>
                        <img
                          src={leg.carriersData[0].logo}
                          alt="airline-logo"
                        />
                      </div>
                    ))}
                </div>
                <div className="flight-details-info">
                  <p>Cabin Luggage Allowance:</p>
                  <ul>
                    {segment.travellerCabinLuggage?.length > 0 &&
                      segment.travellerCabinLuggage.map(
                        (luggage, luggageIndex) => (
                          <li key={luggageIndex}>
                            Traveler Reference: {luggage.travellerReference}
                            <br />
                            Luggage Type: {luggage.luggageAllowance.luggageType}
                            <br />
                            Max Piece: {luggage.luggageAllowance.maxPiece}
                            <br />
                            Personal Item:{" "}
                            {luggage.personalItem ? "Allowed" : "Not Allowed"}
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </div>
            ))
          ) : (
            <p>No segments available</p>
          )}
        </div>
        <aside className="flight-details-sidebar">
          {travellerPrices?.length > 0 && (
            <div className="traveller-prices">
              <h3>Traveller Prices</h3>
              {travellerPrices.map((price, priceIndex) => (
                <div key={priceIndex} className="traveller-price">
                  <p>
                    <strong>Traveller Type:</strong> {price.travellerType} {price.travellerReference}
                  </p>
                  {/* <p><strong>Total Price:</strong> {price.travellerPriceBreakdown.total.units}.{price.travellerPriceBreakdown.total.nanos} {price.travellerPriceBreakdown.total.currencyCode}</p> */}
                  <p>
                    <strong>Base Price:</strong>{" "}
                    {price.travellerPriceBreakdown.baseFare.units}.
                    {price.travellerPriceBreakdown.baseFare.nanos}{" "}
                    {price.travellerPriceBreakdown.baseFare.currencyCode}
                  </p>
                  <p>
                    <strong>Total Price:</strong>{" "}
                    {
                      price.travellerPriceBreakdown.totalWithoutDiscountRounded
                        .units
                    }
                    .
                    {
                      price.travellerPriceBreakdown.totalWithoutDiscountRounded
                        .nanos
                    }{" "}
                    {
                      price.travellerPriceBreakdown.totalWithoutDiscountRounded
                        .currencyCode
                    }
                  </p>
                  <p>
                    <strong>Tax:</strong>{" "}
                    {price.travellerPriceBreakdown.tax.units}.
                    {price.travellerPriceBreakdown.tax.nanos}{" "}
                    {price.travellerPriceBreakdown.tax.currencyCode}
                  </p>
                </div>
              ))}
            </div>
          )}
          {travellers?.length > 0 && (
            <div className="traveller-prices">
              <h3>Travellers</h3>
              {travellers.map((traveller, travellerIndex) => (
                <div key={travellerIndex} className="traveller-price">
                  <p>
                    <strong>Traveller Type:</strong> {traveller.type}
                  </p>
                  {/* <p><strong>Total Price:</strong> {price.travellerPriceBreakdown.total.units}.{price.travellerPriceBreakdown.total.nanos} {price.travellerPriceBreakdown.total.currencyCode}</p> */}
                  <p>
                    <strong>Traveller Age:</strong> {traveller.age ? traveller.age : "Not Found"}
                  </p>
                </div>
              ))}
            </div>
          )}
          <button className="book-btn">
            <Link to={baggagePolicies[0].url} className="navLink2">
              Book Now
            </Link>
          </button>
        </aside>
      </section>
    </>
  );
};

export default FlightDetails;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import axios from "axios";
// import dayjs from "dayjs";

// const FlightDetails = () => {
//   const location = useLocation();
//   const [flightDetails, setFlightDetails] = useState(null);
//   // const [flightDetailsInfo, setFlightDetailsInfo] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchFlightDetails = async () => {
//       const {
//         token
//       } = location.state;
//       const options = {
//         method: "GET",
//         url: "https://booking-com15.p.rapidapi.com/api/v1/flights/getFlightDetails",
//         params: {
//           token
//         },
//         headers: {
//           "x-rapidapi-key":
//             "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
//           "x-rapidapi-host": "booking-com15.p.rapidapi.com",
//         },
//       };

//       try {
//         const response = await axios.request(options);
//         console.log(response?.data);
//         setFlightDetails(response?.data?.data);
//       } catch (error) {
//         console.error(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchFlightDetails();
//   }, [location.state]);

// console.log(flightDetails, "flightDetails")
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!flightDetails) {
//     return <div className="">No Flight details available</div>;
//   }

//   // Destructure flight details for easier access
//   const {
//     segments,
//     tripType,
//     travellerPrices
//   } = flightDetails || {};

//   console.log(segments, "segments")

//   // Assuming there's only one segment in the response based on your provided data
//   // const segment = segments[0];
//   return (
//     <>
//       <section id="home" className="about-us inner">
//         <div className="container">
//           <div className="about-us-content">
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className="single-about-us">
//                   <div className="about-us-txt">
//                     <h2>Flight Details</h2>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-sm-0">
//                 <div className="single-about-us"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className="flight-details-section">
//       <div className="flight-details-main">
//         {segments?.length > 0 ? (
//           segments.map((segment, index) => (
//             <div key={index} className="flight-details-segment">
//               <div className="flight-details-header">
//                 <h3>Flight Information</h3>
//                 <p>Departure City: {segment.departureAirport.cityName} ({segment.departureAirport.code})</p>
//                 <p>Arrival City: {segment.arrivalAirport.cityName} ({segment.arrivalAirport.code})</p>
//                 <p>Departure Time: {dayjs(segment.departureTime).format("DD/MM/YYYY hh:mm")}</p>
//                 <p>Arrival Time: {dayjs(segment.arrivalTime).format("DD/MM/YYYY hh:mm")}</p>
//                 <p>Trip Type: {tripType}</p>
//                 <h4>Legs Information:</h4>
//                 {segment.legs?.length > 0 && segment.legs.map((leg, legIndex) => (
//                   <div key={legIndex} className="flight-details-leg">
//                     <p><strong>Leg {legIndex + 1}:</strong></p>
//                     <p>Departure Airport Name: {leg.departureAirport.name}</p>
//                     <p>Arrival Airport Name: {leg.arrivalAirport.name}</p>
//                     {/* <p>Departure Time: {leg.departureTime}</p> */}
//                     <p>Flight No: {leg.flightInfo.flightNumber}</p>
//                     <p>Cabin Class: {leg.cabinClass}</p>
//                     <p>Airline: {leg.carriersData[0].name}</p>
//                     <img src={leg.carriersData[0].logo} alt="airline-logo" />
//                   </div>
//                 ))}
//               </div>
//               <div className="flight-details-info">
//                 <p>Cabin Luggage Allowance:</p>
//                 <ul>
//                   {segment.travellerCabinLuggage?.length > 0 && segment.travellerCabinLuggage.map((luggage, luggageIndex) => (
//                     <li key={luggageIndex}>
//                       Traveler Reference: {luggage.travellerReference}<br />
//                       Luggage Type: {luggage.luggageAllowance.luggageType}<br/>
//                       Max Piece: {luggage.luggageAllowance.maxPiece}<br />
//                       Personal Item: {luggage.personalItem ? "Allowed" : "Not Allowed"}
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//           ))
//         ) : (
//           <p>No segments available</p>
//         )}
//       </div>
//       <aside className="flight-details-sidebar">
//       {travellerPrices?.length > 0 && (
//           <div className="traveller-prices">
//             <h3>Traveller Prices</h3>
//             {travellerPrices.map((price, priceIndex) => (
//               <div key={priceIndex} className="traveller-price">
//                 <p><strong>Traveller Type:</strong> {price.travellerType}</p>
//                 {/* <p><strong>Total Price:</strong> {price.travellerPriceBreakdown.total.units}.{price.travellerPriceBreakdown.total.nanos} {price.travellerPriceBreakdown.total.currencyCode}</p> */}
//                 <p><strong>Base Price:</strong> {price.travellerPriceBreakdown.baseFare.units}.{price.travellerPriceBreakdown.baseFare.nanos} {price.travellerPriceBreakdown.baseFare.currencyCode}</p>
//                 <p><strong>Total Price:</strong> {price.travellerPriceBreakdown.totalWithoutDiscountRounded.units}.{price.travellerPriceBreakdown.totalWithoutDiscountRounded.nanos} {price.travellerPriceBreakdown.totalWithoutDiscountRounded.currencyCode}</p>
//                 <p><strong>Tax:</strong> {price.travellerPriceBreakdown.tax.units}.{price.travellerPriceBreakdown.tax.nanos} {price.travellerPriceBreakdown.tax.currencyCode}</p>
//               </div>
//             ))}
//           </div>
//         )}
//         <h3>Booking Options</h3>
//         <button className="book-btn">
//           <Link to="/booking" className="navLink2">
//             Book Now
//           </Link>
//         </button>
//       </aside>
//     </section>
//       {/* Booking Section End */}
//     </>
//   );
// };

// export default FlightDetails;
