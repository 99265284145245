export const SITE_NAME = "GroCost Admin";

const { NODE_ENV } = process.env
const { hostname } = window.location

const servers = {
  local: "http://localhost:3027",
  customDev: "https://react.customdev.solutions:3027",
  live: "https://api.travelflyhub.com"
}

var URL;
if (NODE_ENV === "production" && hostname === "react.customdev.solutions") URL = servers.customDev
else if (NODE_ENV === "production" && hostname.includes("travelflyhub.com")) URL = servers.live
else URL = servers.local

export const BASE_URL = URL + "/api/";


export const Newsletter = {
  newsletter: "newsletter",
};

export const Feedback = {
  feedback: "feedback/contact",
};