import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Blog = () => {
  const navigate = useNavigate();

  const handleReadMore = (image, details) => {
    navigate('/blogDetails', { state: { image, details } });
  };

  return (
    <>
      {/* about-us start */}
      <section id="home" className="about-us inner">
        <div className="container">
          <div className="about-us-content">
            <div className="row">
              <div className="col-sm-12">
                <div className="single-about-us">
                  <div className="about-us-txt">
                    <h2>Blogs</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-0">
                <div className="single-about-us"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-us end */}

      {/* blog start */}
      <section id="blog" className="blog">
        <div className="container">
          <div className="blog-details">
            <div className="gallary-header text-center">
              <h2>Discover the Best Travel Tips and Guides with Travel Fly Hub</h2>
              <p>
                Welcome to the Travel Fly Hub blog! Here, we bring you the latest travel tips, destination guides, and insider advice to make your journeys unforgettable. Whether you are a seasoned traveler or planning your first adventure, our blog is your go-to resource for all things travel.
              </p>
            </div>
            <div className="blog-content">
              <div className="row">
                {[
                  // {
                  //   id: 1,
                  //   date: '15 november 2017',
                  //   image: 'assets/images/b22.jpg',
                  //   title: 'Top 10 Hidden Gems to Explore in Europe',
                  //   description:
                  //     'Europe is filled with well-known tourist spots, but in this article, we uncover the lesser-known destinations that offer unique experiences away from the crowds. Discover charming villages, stunning landscapes, and cultural treasures that are off the beaten path.',
                  // },
                  // {
                  //   id: 2,
                  //   date: '15 november 2017',
                  //   image: 'assets/images/b21.jpg',
                  //   title: 'A Complete Guide to Backpacking in South America',
                  //   description:
                  //     'Backpacking across South America can be the adventure of a lifetime. Our comprehensive guide covers everything you need to know, from packing essentials to safety tips and must-visit spots in countries like Brazil, Argentina, and Peru.',
                  // },
                  // {
                  //   id: 3,
                  //   date: '15 november 2017',
                  //   image: 'assets/images/b20.webp',
                  //   title: 'The Ultimate Road Trip Routes in the USA',
                  //   description:
                  //     'The United States offers some of the most scenic and diverse road trip routes in the world. From the coastal highways of California to the historic trails of the East Coast, we detail the best routes, attractions along the way, and tips for a smooth journey.',
                  // },
                  // {
                  //   id: 4,
                  //   date: '15 november 2017',
                  //   image: 'assets/images/b19.jpg',
                  //   title: 'How to Travel on a Budget: Tips and Tricks',
                  //   description:
                  //     "Traveling doesn’t have to break the bank. In this article, we share practical tips on how to save money on flights, accommodation, food, and activities without compromising on the experience. Learn how to make the most of your travel budget.",
                  // },
                  // {
                  //   id: 5,
                  //   date: '15 november 2017',
                  //   image: 'assets/images/b18.jpg',
                  //   title: "Exploring Asia: A Guide to the Continent's Cultural Wonders",
                  //   description:
                  //     'Asia is a continent rich in culture and history. We delve into the top cultural attractions, festivals, and traditions you should experience. From the temples of Thailand to the cherry blossoms in Japan, discover the wonders of Asia.',
                  // },
                  {
                    id: 1,
                    date: '15 november 2017',
                    image: 'assets/images/b22.jpg',
                    title: "Travel Hacks for Saving Money on Your Next Adventure",
                    description:
                      "Let's explore clever hacks to save money on your next trip! Tips for finding budget-friendly flights, scoring deals on accommodation, and traveling like a pro without sacrificing the adventure!",
                    details: 'details1'
                  },
                  {
                    id: 2,
                    date: '15 november 2017',
                    image: 'assets/images/b20.webp',
                    title: "The Ultimate Guide to Packing Light for Your Trip",
                    description:
                      "Ditch the baggage blues! Learn how to travel smarter, not heavier. From choosing versatile clothing to mastering space-saving techniques, this guide will help you pack like a pro and maximize your travel tales!",
                    details: 'details2'
                  },
                  {
                    id: 3,
                    date: '15 november 2017',
                    image: 'assets/images/b19.jpg',
                    title: "Must-Have Travel Apps for a Smooth and Connected Journey",
                    description:
                      "Never get lost, stressed, or disconnected again! This guide reveals the must-have travel apps for a smooth and connected journey.",
                    details: 'details3'
                  }
                ].map((blog, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="thumbnail">
                      <h2>trending news <span>{blog.date}</span></h2>
                      <div className="thumbnail-img">
                        <img src={blog.image} alt="blog-img"/>
                        <div className="thumbnail-img-overlay"></div>
                      </div>
                      <div className="caption">
                       <div className="blog-txt">
                         <h3>
                          <Link>{blog.title}</Link>
                         </h3>
                         <p>
                           {blog.description}
                         </p>
                         {/* <Link onClick={() => handleReadMore(blog.image)}>Read More</Link> */}
                         <button onClick={() => handleReadMore(blog.image, blog.details)}>
                          <Link>Read More</Link>
                         </button>
                       </div>
                     </div>
                      {/* <div className="caption">
                        <div className="blog-txt">
                          <h3>
                            <button onClick={() => handleReadMore(blog.image)}>
                              {blog.title}
                            </button>
                          </h3>
                          <p>{blog.description}</p>
                          <button onClick={() => handleReadMore(blog.image)}>Read More</button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* blog end */}
    </>
  );
};

export default Blog;




// import React from 'react'
// import { useNavigate } from 'react-router-dom'
// import { Link } from 'react-router-dom'
// const blog = () => {
// //   const navigate = useNavigate()

//   return (
//     <>
    
//     {/* about-us start */}
//     <section id="home" className="about-us inner">
//       <div className="container">
//         <div className="about-us-content">
//           <div className="row">
//             <div className="col-sm-12">
//               <div className="single-about-us">
                
//                 <div className="about-us-txt">
//                   <h2>Blogs</h2>
//                 </div>
//               </div>
//             </div>
//             <div className="col-sm-0">
//               <div className="single-about-us"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//     {/* about-us end */}

//     {/* blog start */}
//     <section id="blog" className="blog">
//       <div className="container">
//         <div className="blog-details">
//           <div className="gallary-header text-center">
//             <h2>Discover the Best Travel Tips and Guides with Travel Fly Hub</h2>
//             <p>Welcome to the Travel Fly Hub blog! Here, we bring you the latest travel tips, destination guides, and insider advice to make your journeys unforgettable. Whether you are a seasoned traveler or planning your first adventure, our blog is your go-to resource for all things travel.</p>
//           </div>
//           <div className="blog-content">
//             <div className="row">
//               <div className="col-md-4">
//                 <div className="thumbnail">
//                   <h2>trending news <span>15 november 2017</span></h2>
//                   <div className="thumbnail-img">
//                     <img src="assets/images/b1.jpg" alt="blog-img" />
//                     <div className="thumbnail-img-overlay"></div>
//                   </div>

//                   <div className="caption">
//                     <div className="blog-txt">
//                       <h3>
//                         <Link to="/blogDetails">
//                         Top 10 Hidden Gems to Explore in Europe
//                         </Link>
//                       </h3>
//                       <p>
//                       Europe is filled with well-known tourist spots, but in this article, we uncover the lesser-known destinations that offer unique experiences away from the crowds. Discover charming villages, stunning landscapes, and cultural treasures that are off the beaten path.
//                       </p>
//                       <Link to="/blogDetails">Read More</Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className=" col-md-4">
//                 <div className="thumbnail">
//                   <h2>trending news <span>15 november 2017</span></h2>
//                   <div className="thumbnail-img">
//                     <img src="assets/images/b2.jpg" alt="blog-img" />
//                     <div className="thumbnail-img-overlay"></div>
//                   </div>
//                   <div className="caption">
//                     <div className="blog-txt">
//                       <h3>
//                         <Link to="/blogDetails">
//                         A Complete Guide to Backpacking in South America
//                         </Link>
//                       </h3>
//                       <p>
//                       Backpacking across South America can be the adventure of a lifetime. Our comprehensive guide covers everything you need to know, from packing essentials to safety tips and must-visit spots in countries like Brazil, Argentina, and Peru.
//                       </p>
//                       <Link to="/blogDetails">Read More</Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-md-4">
//                 <div className="thumbnail">
//                   <h2>trending news <span>15 november 2017</span></h2>
//                   <div className="thumbnail-img">
//                     <img src="assets/images/b3.jpg" alt="blog-img" />
//                     <div className="thumbnail-img-overlay"></div>
//                   </div>
//                   <div className="caption">
//                     <div className="blog-txt">
//                       <h3>
//                         <Link to="/blogDetails"
//                           >The Ultimate Road Trip Routes in the USA
//                         </Link>
//                       </h3>
//                       <p>
//                       The United States offers some of the most scenic and diverse road trip routes in the world. From the coastal highways of California to the historic trails of the East Coast, we detail the best routes, attractions along the way, and tips for a smooth journey.
//                         </p>
//                       <Link to="/blogDetails">Read More</Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-sm-4 col-md-4">
//                 <div className="thumbnail">
//                   <h2>trending news <span>15 november 2017</span></h2>
//                   <div className="thumbnail-img">
//                     <img src="assets/images/b1.jpg" alt="blog-img" />
//                     <div className="thumbnail-img-overlay"></div>
//                   </div>

//                   <div className="caption">
//                     <div className="blog-txt">
//                       <h3>
//                         <Link to="/blogDetails">
//                         How to Travel on a Budget: Tips and Tricks
//                         </Link>
//                       </h3>
//                       <p>
//                       Traveling doesn’t have to break the bank. In this article, we share practical tips on how to save money on flights, accommodation, food, and activities without compromising on the experience. Learn how to make the most of your travel budget.
//                       </p>
//                       <Link to="/blogDetails">Read More</Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-sm-4 col-md-4">
//                 <div className="thumbnail">
//                   <h2>trending news <span>15 november 2017</span></h2>
//                   <div className="thumbnail-img">
//                     <img src="assets/images/b2.jpg" alt="blog-img" />
//                     <div className="thumbnail-img-overlay"></div>
//                   </div>
//                   <div className="caption">
//                     <div className="blog-txt">
//                       <h3>
//                         <Link to="/blogDetails">
//                         Exploring Asia: A Guide to the Continent's Cultural Wonders
//                         </Link>
//                       </h3>
//                       <p>
//                       Asia is a continent rich in culture and history. We delve into the top cultural attractions, festivals, and traditions you should experience. From the temples of Thailand to the cherry blossoms in Japan, discover the wonders of Asia.
//                       </p>
//                       <Link to="/blogDetails">Read More</Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//     {/* blog end */}
//     </>
//   )
// }

// export default blog