import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ReactLoading from "react-loading";

const HotelDetails = () => {
  const location = useLocation();
  console.log(location, "location");
  const [hotelDetails, setHotelDetails] = useState(null);
  const [hotelDetailsInfo, setHotelDetailsInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHotelDetails = async () => {
      const {
        hotel_id,
        arrival_date,
        departure_date,
        adults,
        children_age,
        room_qty,
      } = location.state;
      const options = {
        method: "GET",
        url: "https://booking-com15.p.rapidapi.com/api/v1/hotels/getHotelDetails",
        params: {
          hotel_id,
          arrival_date,
          departure_date,
          adults,
          children_age,
          room_qty,
          units: "metric",
          temperature_unit: "c",
          languagecode: "en-us",
          currency_code: "EUR",
        },
        headers: {
          "x-rapidapi-key":
            "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
          "x-rapidapi-host": "booking-com15.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.request(options);
        console.log(response?.data);
        setHotelDetails(response?.data?.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchHotelDetails();
  }, [location.state]);

  useEffect(() => {
    const fetchHotelDetailsInfo = async () => {
      const { hotel_id } = location.state;
      const options = {
        method: "GET",
        url: "https://booking-com15.p.rapidapi.com/api/v1/hotels/getDescriptionAndInfo",
        params: {
          hotel_id,
          languagecode: "en-us",
        },
        headers: {
          "x-rapidapi-key":
            "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
          "x-rapidapi-host": "booking-com15.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.request(options);
        console.log(response?.data);
        setHotelDetailsInfo(response?.data?.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchHotelDetailsInfo();
  }, [location.state]);

  const { hotel_image } = location?.state;

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <ReactLoading type="spin" color="#49949C" height={100} width={100} />
      </div>
    );
  }

  // if (!hotelDetails || Object.keys(hotelDetails).length === 0) {
  //   return (
  //     <div className="flex justify-center items-center h-screen text-red-500">
  //       No hotel details available
  //     </div>
  //   );
  // }

  if (!hotelDetails) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        No hotel details available
      </div>
    );
  }

  return (
    <>
      <section id="home" className="about-us inner">
        <div className="container">
          <div className="about-us-content">
            <div className="row">
              <div className="col-sm-12">
                <div className="single-about-us">
                  <div className="about-us-txt">
                    <h2>{hotelDetails.hotel_name}</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-0">
                <div className="single-about-us"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hotel Details Section */}
      <section id="hotel-details" className="container my-4">
        <div className="row">
          <div className="col-lg-8">
            <div className="card">
              <div className="flex items-center rounded-md justify-center overflow-hidden h-24 w-24 mx-auto mb-2 mt-2">
                <img
                  src={hotel_image.photoUrls[0]}
                  alt="package-place"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="card-body">
                <h2 className="card-title">{hotelDetails.hotel_name}</h2>
                <p className="card-text">
                  <strong>City:</strong> {hotelDetails.city}
                </p>
                <h3 className="mt-4">Hotel Description:</h3>
                {hotelDetailsInfo.map((item, index) => (
                  <p key={index} className="mb-4">
                    {item.description}
                  </p>
                ))}
                <h3>Hotel Address:</h3>
                <p>{hotelDetails.address}</p>
                <div className="hotel-details-info">
                  <h3 className="mt-4">Information</h3>
                  <p>
                    <strong>Arrival Date:</strong> {hotelDetails.arrival_date}{" "}
                    <br />
                    <strong>Departure Date:</strong>{" "}
                    {hotelDetails.departure_date}
                  </p>
                  {hotelDetails.block.map((item, index) => (
                    <div key={index} className="mb-4">
                      <p>
                        <strong>Meal Plan:</strong> {item.mealplan} <br />
                        <strong>Max Children Free:</strong>{" "}
                        {item.max_children_free}
                      </p>
                      <p>
                        <strong>Room Count:</strong> {item.room_count} <br />
                        <strong>Available Rooms:</strong>{" "}
                        {hotelDetails.available_rooms}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Facilities</h3>
                <ul className="list-unstyled">
                  {hotelDetails.facilities_block.facilities.map(
                    (facility, index) => (
                      <li key={index}>
                        <i className={`icon-${facility.icon}`}></i>{" "}
                        {facility.name}
                      </li>
                    )
                  )}
                </ul>
                <h3>Policies</h3>
                <ul className="list-unstyled">
                  {hotelDetails.block.map((item, blockIndex) =>
                    item.block_text.policies.map((policy, policyIndex) => (
                      <li key={`${blockIndex}-${policyIndex}`}>
                        {policy.content}
                      </li>
                    ))
                  )}
                </ul>
                <h3>Family Facilities</h3>
                <ul className="list-unstyled">
                  {hotelDetails.family_facilities.map((facility, index) => (
                    <li key={index}>{facility}</li>
                  ))}
                </ul>
                <button className="btn btn-primary">
                  <Link to={hotelDetails.url} className="navLink2 text-white">
                    Visit Booking
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hotel Details Section */}
    </>
  );
};

export default HotelDetails;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import axios from "axios";

// const HotelDetails = () => {
//   const location = useLocation();
//   const [hotelDetails, setHotelDetails] = useState(null);
//   const [hotelDetailsInfo, setHotelDetailsInfo] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchHotelDetails = async () => {
//       const {
//         hotel_id,
//         arrival_date,
//         departure_date,
//         adults,
//         children_age,
//         room_qty,
//       } = location.state;
//       const options = {
//         method: "GET",
//         url: "https://booking-com15.p.rapidapi.com/api/v1/hotels/getHotelDetails",
//         params: {
//           hotel_id,
//           arrival_date,
//           departure_date,
//           adults,
//           children_age,
//           room_qty,
//           units: "metric",
//           temperature_unit: "c",
//           languagecode: "en-us",
//           currency_code: "EUR",
//         },
//         headers: {
//           "x-rapidapi-key":
//             "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
//           "x-rapidapi-host": "booking-com15.p.rapidapi.com",
//         },
//       };

//       try {
//         const response = await axios.request(options);
//         console.log(response?.data);
//         setHotelDetails(response?.data?.data);
//       } catch (error) {
//         console.error(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchHotelDetails();
//   }, [location.state]);

//   useEffect(() => {
//     const fetchHotelDetailsInfo = async () => {
//       const { hotel_id } = location.state;
//       const options = {
//         method: "GET",
//         url: "https://booking-com15.p.rapidapi.com/api/v1/hotels/getDescriptionAndInfo",
//         params: {
//           hotel_id,
//           languagecode: "en-us",
//         },
//         headers: {
//           "x-rapidapi-key":
//             "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
//           "x-rapidapi-host": "booking-com15.p.rapidapi.com",
//         },
//       };

//       try {
//         const response = await axios.request(options);
//         console.log(response?.data);
//         setHotelDetailsInfo(response?.data?.data);
//       } catch (error) {
//         console.error(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchHotelDetailsInfo();
//   }, [location.state]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!hotelDetails) {
//     return <div>No hotel details available</div>;
//   }
//   return (
//     <>
//       {/* about-us end */}
//       <section id="home" className="about-us inner">
//         <div className="container">
//           <div className="about-us-content">
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className="single-about-us">
//                   <div className="about-us-txt">
//                     <h2>{hotelDetails.hotel_name}</h2>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-sm-0">
//                 <div className="single-about-us"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* about-us end */}

//       {/* Booking Section Start */}
//       {/* <div id="booking">
//       <div className="container">
//         <div className="section-header">
//         <img src="assets/images/b1.jpg" className="blog-details-image"/>
//           <h2>trending news</h2>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
//             mi libero. Quisque convallis, enim at venenatis tincidunt. Lorem
//             ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in mi
//             libero. Quisque convallis, enim at venenatis tincidunt. Lorem ipsum
//             dolor sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt. Lorem ipsum dolor
//             sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt.
//           </p>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
//             mi libero. Quisque convallis, enim at venenatis tincidunt. Lorem
//             ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in mi
//             libero. Quisque convallis, enim at venenatis tincidunt. Lorem ipsum
//             dolor sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt. Lorem ipsum dolor
//             sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt. Lorem ipsum dolor
//             sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt.
//           </p>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
//             mi libero. Quisque convallis, enim at venenatis tincidunt. Lorem
//             ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in mi
//             libero. Quisque convallis, enim at venenatis tincidunt. Lorem ipsum
//             dolor sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt. Lorem ipsum dolor
//             sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt. Lorem ipsum dolor
//             sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt. Lorem ipsum dolor
//             sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt. Lorem ipsum dolor
//             sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt. Lorem ipsum dolor
//             sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt. Lorem ipsum dolor
//             sit amet, consectetur adipiscing elit. Maecenas in mi libero.
//             Quisque convallis, enim at venenatis tincidunt.
//           </p>
//         </div>
//       </div>
//     </div> */}

//       <section className="hotel-details-section">
//         <div className="hotel-details-main">
//           <img
//             src="assets/images/b1.jpg"
//             className="hotel-details-image"
//             alt="hotel"
//           />
//           <div className="hotel-details-header">
//             <h3>{hotelDetails.hotel_name.slice(0, 20)}...</h3>
//             <p>City: {hotelDetails.city}</p>
//           </div>
//           {/* <h3>{hotelDetails.hotel_name}</h3> */}
//           <h2>Hotel Description:</h2>
//           {hotelDetailsInfo.map((item, index) => (
//             <>
//               <p>{item.description}</p>
//             </>
//           ))}
//           <h2>Hotel Address:</h2>
//           <p>{hotelDetails.address}</p>
//           <div className="hotel-details-info">
//             <h3>Information</h3>
//             <p>
//               <strong>Arrival Date:</strong> {hotelDetails.arrival_date} <br />
//               <strong>Departure Date:</strong> {hotelDetails.departure_date}
//             </p>
//             {hotelDetails.block.map((item, index) => (
//               <>
//                 <p>
//                   <strong>Meal Plan:</strong> {item.mealplan} <br />
//                   <strong>Max Children Free:</strong> {item.max_children_free}
//                 </p>
//                 <p>
//                   <strong>Room Count:</strong> {item.room_count} <br />
//                   <strong>Available Rooms:</strong>{" "}
//                   {hotelDetails.available_rooms}
//                 </p>
//               </>
//             ))}
//           </div>
//         </div>
//         <aside className="hotel-details-sidebar">
//           {/* <h3>Highlights</h3>
//           <ul>
//             {hotelDetails.rooms['19160501'].highlights.map((highlight, index) => (
//               <li key={index}>
//                 <i className={`icon-${highlight.icon}`}></i>
//                 {highlight.translated_name}
//               </li>
//             ))}
//           </ul> */}
//           <h3>Facilities</h3>
//           <ul>
//             {hotelDetails.facilities_block.facilities.map((facility, index) => (
//               <li key={index}>
//                 <i className={`icon-${facility.icon}`}></i>
//                 {facility.name}
//               </li>
//             ))}
//           </ul>
//           <h3>Policies</h3>
//           <ul>
//             {hotelDetails.block.map((item, blockIndex) =>
//               item.block_text.policies.map((policy, policyIndex) => (
//                 <li key={`${blockIndex}-${policyIndex}`}>{policy.content}</li>
//               ))
//             )}
//           </ul>
//           <h3>Family Facilities</h3>
//           <ul>
//             {hotelDetails.family_facilities.map((facility, index) => (
//               <li key={index}>{facility}</li>
//             ))}
//           </ul>
//           <button className="book-btn">
//             <Link to="/booking" className="navLink2">
//               Visit Booking
//             </Link>
//           </button>
//           {/* <a href={hotelDetails.url} className="btn btn-primary">
//             Visit Booking Page
//           </a> */}
//         </aside>
//       </section>

//       {/* Booking Section End */}
//     </>
//   );
// };

// export default HotelDetails;
