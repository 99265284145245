import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import { FaStar, FaAngleRight, FaCalendar } from "react-icons/fa";

const Hotel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location?.state) {
      setHotels(location.state);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [location]);

  const handleReadMore = (hotelId, checkIn, checkOut, hotelImage) => {
    navigate("/hotelDetails", {
      state: {
        hotel_id: hotelId,
        arrival_date: checkIn,
        departure_date: checkOut,
        adults: "4",
        children_age: "1,17",
        room_qty: "2",
        hotel_image: hotelImage,
      },
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <ReactLoading type="spin" color="#49949C" height={100} width={100} />
      </div>
    );
  }

  if (!hotels.length) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "#EF4444",
        }}
      >
        No Hotels Available!
      </div>
    );
  }

  return (
    <>
      <section id="home" className="about-us inner">
        <div className="container">
          <div className="about-us-content">
            <div className="row">
              <div className="col-sm-12">
                <div className="single-about-us">
                  <div className="about-us-txt">
                    <h2>List Of All Hotels</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-0">
                <div className="single-about-us"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pack" className="packages">
        <div className="container">
          <div className="gallary-header text-center">
            <h2>Find Your Perfect Hotel</h2>
            <p>Explore our unforgettable Hotels for your dream trips!</p>
          </div>
          <div className="packages-content">
            <div className="row">
              {hotels.map((hotel, index) => (
                <div key={index} className="col-md-4 col-sm-6">
                  <div className="single-package-item pt-2">
                    <div className="flex items-center justify-center rounded-full overflow-hidden h-24 w-24 mx-auto mb-2">
                      <img
                        src={hotel.property.photoUrls[0]}
                        alt="package-place"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <div className="single-package-item-txt">
                      <h3>
                        {hotel.property.name}{" "}
                        <span className="pull-right">{hotel.price}</span>
                      </h3>
                      <div className="packages-para">
                        <p>
                          <span>
                            <i className="fa fa-angle-right"></i> CheckIn:{" "}
                            {hotel.property.checkinDate}
                          </span>
                          <i className="fa fa-angle-right"></i> CheckOut:{" "}
                          {hotel.property.checkoutDate}
                        </p>
                        <p>
                          <span>
                            <i className="fa fa-angle-right"></i> Quality Class:{" "}
                            {hotel.property.qualityClass}
                          </span>
                          <i className="fa fa-angle-right"></i> Wishlist Name:{" "}
                          {hotel.property.wishlistName}
                        </p>
                      </div>
                      <div className="packages-review">
                        <p>
                          {/* {[...Array(hotel.property.reviewCount)].map((_, i) => (
                            <FaStar key={i} />
                          ))} */}
                          <span>{hotel.property.reviewCount} reviews</span>
                        </p>
                      </div>
                      {/* <div className="about-btn flex justify-center items-center h-32"> */}
                      <div className="flex justify-center items-center w-100">
                        <div className="about-btn">
                          <button
                            className="about-view packages-btn"
                            onClick={() =>
                              handleReadMore(
                                hotel.hotel_id,
                                hotel.property.checkinDate,
                                hotel.property.checkoutDate,
                                hotel.property
                              )
                            }
                          >
                            Read More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hotel;

// Old Code

// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { useLocation } from 'react-router-dom';

// const Hotel = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     console.log(location, "location")
//     const hotels = location?.state;
//     console.log(hotels, "hotels");

//     if (!hotels) {
//         // Handle case where hotels data is null
//         return <div>No hotels data available</div>;
//     }

//     const handleReadMore = (hotelId, checkIn, checkOut) => {
//       navigate("/hotelDetails", {
//           state: {
//               hotel_id: hotelId,
//               arrival_date: checkIn,
//               departure_date: checkOut,
//               adults: '4',
//               children_age: '1,17',
//               room_qty: '2'
//           }
//       });
//   };

//   return (
//     <>
//       {/* about-us start */}
//       <section id="home" className="about-us inner">
//         <div className="container">
//           <div className="about-us-content">
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className="single-about-us">
//                   <div className="about-us-txt">
//                     <h2>List Of All Hotels</h2>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-sm-0">
//                 <div className="single-about-us"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* about-us end */}

//       {/* blog start */}
//       <section id="pack" class="packages">
//         <div class="container">
//           <div class="gallary-header text-center">
//             <h2>Find Your Perfect Hotel</h2>
//             <p>Explore our unforgettable Hotels for your dream trips!</p>
//           </div>
//           <div className="packages-content">
//             <div className="row">
//               {hotels.map((hotel, index) => (
//                 <div key={index} className="col-md-4 col-sm-6">
//                   <div className="single-package-item">
//                     <img src={hotel.property.photoUrls[0]} alt="package-place" />
//                     <div className="single-package-item-txt">
//                       <h3>
//                         {hotel.property.name}{" "}
//                         <span className="pull-right">{hotel.price}</span>
//                       </h3>
//                       <div className="packages-para">
//                         <p>
//                           <span>
//                             <i className="fa fa-angle-right"></i>{" "}
//                             CheckIn: {hotel.property.checkinDate}
//                           </span>
//                           <i className="fa fa-angle-right"></i>{" "}
//                           CheckOut: {hotel.property.checkoutDate}
//                         </p>
//                         <p>
//                           <span>
//                             <i className="fa fa-angle-right"></i>{" "}
//                             Quality Class: {hotel.property.qualityClass}
//                           </span>
//                           <i className="fa fa-angle-right"></i>{" "}
//                           Wishlist Name: {hotel.property.wishlistName}
//                           {/* Review Score Word: {hotel.property.reviewScoreWord} */}
//                         </p>
//                       </div>
//                       <div className="packages-review">
//                         <p>
//                           {[...Array(hotel.property.reviewCount)].map((_, i) => (
//                             <i key={i} className="fa fa-star"></i>
//                           ))}
//                           <span>{hotel.property.reviewCount} reviews</span>
//                         </p>
//                       </div>
//                       <div className="about-btn">
//                         <button className="about-view packages-btn" onClick={() => handleReadMore(hotel.hotel_id, hotel.property.checkinDate, hotel.property.checkoutDate)}>
//                           Read More
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Hotel;
