import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaCar, FaMapMarkerAlt, FaMoneyBillAlt, FaSuitcase } from "react-icons/fa";
import ReactLoading from "react-loading";

const CarRental = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location)
  const [isLoading, setIsLoading] = useState(true);
  const { search_results, search_key } = location?.state;

  useEffect(() => {
    // Simulating data fetching for demonstration. Remove this if data is fetched via API
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust timeout as needed
  }, []);

  if (!search_results || (Array.isArray(search_results) && search_results.length === 0)) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", color: "#EF4444"}}>
        No Car is Avaialbe For Rent!
      </div>
    );
  }

  // if (!search_results) {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
  //       No car rental data available
  //     </div>
  //   );
  // }

  const handleReadMore = (vehicleId) => {
    navigate("/carRentalDetails", {
      state: {
        vehicleId,
        search_key,
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <ReactLoading type="spin" color="#49949C" height={100} width={100} />
        </div>
      ) : (
        <>
          <section id="home" className="about-us inner">
            <div className="container">
              <div className="about-us-content">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="single-about-us">
                      <div className="about-us-txt">
                        <h2>List Of All Available Cars</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-0">
                    <div className="single-about-us"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="pack" className="packages">
            <div className="container">
              <div className="gallary-header text-center">
                <h2>Find Your Perfect Car</h2>
                <p>Explore our available cars for your trips!</p>
              </div>
              <div className="packages-content">
                <div className="row">
                  {search_results.map((car, index) => (
                    <div key={index} className="col-md-4 col-sm-6">
                      <div className="single-package-item">
                        <img
                          src={car.vehicle_info.image_url}
                          alt={car.vehicle_info.v_name}
                          className="img-responsive"
                        />
                        <div className="single-package-item-txt">
                          <h3>
                            {car.vehicle_info.v_name}
                            <span className="pull-right">
                              {car.pricing_info.currency} {car.pricing_info.price}
                            </span>
                          </h3>
                          <div className="packages-para">
                            <p className="icon-text">
                              <FaCar /> <strong>Group:</strong> {car.vehicle_info.group}
                            </p>
                            <p className="icon-text">
                              <FaMapMarkerAlt />{" "}
                              {car.supplier_info.address.length > 50
                                ? `${car.supplier_info.address.slice(0, 50)}...`
                                : car.supplier_info.address}
                            </p>
                            <p className="icon-text">
                              <FaMoneyBillAlt /> <strong>Fuel Policy: </strong>{" "}
                              {car.vehicle_info.fuel_policy}
                            </p>
                            <p className="icon-text">
                              <FaSuitcase /> <strong>Suitcases: </strong>{" "}
                              {car.vehicle_info.suitcases.big} large,{" "}
                              {car.vehicle_info.suitcases.small} small
                            </p>
                          </div>
                          <div className="about-btn">
                            <button
                              className="about-view packages-btn"
                              onClick={() => handleReadMore(car.vehicle_id)}
                            >
                              Read More
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default CarRental;



//Old code

// import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import {
//   FaCar,
//   FaMapMarkerAlt,
//   FaMoneyBillAlt,
//   FaSuitcase,
// } from "react-icons/fa";

// const CarRental = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   console.log(location, "location");
//   const { search_results, search_key } = location?.state;
//   //   const aggregation = location?.state;
//   console.log(search_results, "searchResults");

//   if (!search_results) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         No car rental data available
//       </div>
//     );
//   }

//   const handleReadMore = (vehicleId) => {
//     console.log(vehicleId, "vehicleId");
//     navigate("/carRentalDetails", {
//       state: {
//         vehicleId,
//         search_key,
//       },
//     });
//   };

//   return (
//     <>
//       <section id="home" className="about-us inner">
//         <div className="container">
//           <div className="about-us-content">
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className="single-about-us">
//                   <div className="about-us-txt">
//                     <h2>List Of All Available Cars</h2>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-sm-0">
//                 <div className="single-about-us"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section id="pack" className="packages">
//         <div className="container">
//           <div className="gallary-header text-center">
//             <h2>Find Your Perfect Car</h2>
//             <p>Explore our available cars for your trips!</p>
//           </div>
//           <div className="packages-content">
//             <div className="row">
//               {search_results.map((car, index) => (
//                 <div key={index} className="col-md-4 col-sm-6">
//                   <div className="single-package-item">
//                     <img
//                       src={car.vehicle_info.image_url}
//                       alt={car.vehicle_info.v_name}
//                       className="img-responsive"
//                     />
//                     <div className="single-package-item-txt">
//                       <h3>
//                         {car.vehicle_info.v_name}
//                         <span className="pull-right">
//                           {car.pricing_info.currency} {car.pricing_info.price}
//                         </span>
//                       </h3>
//                       <div className="packages-para">
//                         <p className="icon-text" >
//                           <FaCar /> <strong>Group:</strong> {car.vehicle_info.group}
//                         </p>
//                         <p className="icon-text">
//                           <FaMapMarkerAlt /> {car.supplier_info.address.length > 50 ? `${car.supplier_info.address.slice(0, 50)}...` : car.supplier_info.address}
//                         </p>
//                         <p className="icon-text">
//                           <FaMoneyBillAlt /> <strong>Fuel Policy: </strong>{" "}
//                           {car.vehicle_info.fuel_policy}
//                         </p>
//                         <p className="icon-text">
//                           <FaSuitcase /> <strong>Suitcases: </strong>{" "}
//                           {car.vehicle_info.suitcases.big} large,{" "}
//                           {car.vehicle_info.suitcases.small} small
//                         </p>
//                       </div>
//                       <div className="about-btn">
//                         <button
//                           className="about-view packages-btn"
//                           onClick={() => handleReadMore(car.vehicle_id)}
//                         >
//                           Read More
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default CarRental;
