import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { FaCar, FaStar, FaCheckCircle } from "react-icons/fa";
import ReactLoading from "react-loading";

const CarRentalDetails = () => {
  const location = useLocation();
  const [carRentalDetails, setCarRentalDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCarRentalDetails = async () => {
      const { vehicleId, search_key } = location.state;
      const options = {
        method: "GET",
        url: "https://booking-com15.p.rapidapi.com/api/v1/cars/vehicleDetails",
        params: { vehicle_id: vehicleId, search_key },
        headers: {
          "x-rapidapi-key":
            "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
          "x-rapidapi-host": "booking-com15.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.request(options);
        setCarRentalDetails(response?.data?.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCarRentalDetails();
  }, [location.state]);

  if (loading) {
    return (
      <div className="loader-container">
        <ReactLoading type="spin" color="#49949C" height={100} width={100} />
      </div>
    );
  }

  if (!carRentalDetails) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>No Rent Car details available</div>;
  }

  const { vehicle, meta, depots, whatsIncluded, content, supplier, extras, links} =
    carRentalDetails || {};

  return (
    <>
      <section id="home" className="about-us inner">
        <div className="container">
          <div className="about-us-content">
            <div className="row">
              <div className="col-sm-12">
                <div className="single-about-us">
                  <div className="about-us-txt">
                    <h2>Rent Car Details</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-0">
                <div className="single-about-us"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="car-rental-details containerOne">
        {/* Vehicle Information */}
        <h2 className="text-center">Vehicle Information</h2>
        <div className="car-details row">
          <div className="col-md-6">
            <img
              src={vehicle.imageUrl}
              alt="Car"
              className="car-image img-fluid"
            />
          </div>
          <div className="col-md-6">
            <p>Model: {vehicle.makeAndModel}</p>
            <p>Car Class:{vehicle.carClass}</p>
            <p>Transmission: {vehicle.specification.transmission}</p>
            <p>
              Air Conditioning:{" "}
              {vehicle.specification.airConditioning ? "Yes" : "No"}
            </p>
          </div>
        </div>

        {/* Supplier Information */}
        <h2 className="text-center">Supplier Information</h2>
        <div className="supplier-info row mt-4 border p-3 rounded">
      <div className="col-md-3 d-flex align-items-center">
        <img
          src={supplier.imageUrl}
          alt="Supplier Logo"
          className="supplier-logo img-fluid"
        />
      </div>
      <div className="col-md-9">
        <h3 className="mb-3">{supplier.name}</h3>
        <div className="supplier-rating mb-3">
          {/* {[...Array(supplier.rating)].map((_, i) => (
            <FaStar key={i} className="star-icon text-warning" />
          ))} */}
          <span className="ml-2">Rating: {supplier.rating.toFixed(1)}</span>
          <span className="text-muted ml-1">(Based on {content.carCard.supplier.rating.subtitle})</span>
        </div>
        <p className="mb-1">Rating Title: {content.carCard.supplier.rating.title}</p>
        <p className="mb-1">Rating Average: {content.carCard.supplier.rating.average}</p>
      </div>
    </div>
        {/* <div className="supplier-info row mt-4">
          <div className="col-md-3">
            <img
              src={supplier.imageUrl}
              alt="Supplier Logo"
              className="supplier-logo img-fluid"
            />
          </div>
          <div className="col-md-9">
            <h3>{supplier.name}</h3>
            <div className="supplier-rating">
              {[...Array(supplier.rating)].map((_, i) => (
                <FaStar key={i} className="star-icon"/>
              ))}
             
              <span>{supplier.rating}</span> (
              {content.carCard.supplier.rating.subtitle})
            </div>
            <p>Rating Title: {content.carCard.supplier.rating.title}</p>
            <p>Rating Average: {content.carCard.supplier.rating.average}</p>
          </div>
        </div> */}

        {/* Pickup and Drop-off Information */}
        <div className="depot-info row mt-4"> 
          <div className="col-md-6">
            <h3>Pickup Location</h3>
            <p>{depots.pickup.name}</p>
            <p>{depots.pickup.address}</p>
          </div>
          <div className="col-md-6">
            <h3>Drop-off Location</h3>
            <p>{depots.dropoff.name}</p>
            <p>{depots.dropoff.address}</p>
          </div>
        </div>

        {/* Included Features */}
        <div className="whats-included mt-4">
          <h3>{whatsIncluded.title}</h3>
          <ul>
            {whatsIncluded.items.map((item, index) => (
              <li key={index}>
                <FaCheckCircle className="tick-icon" />
                <span>{item.text}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="extras mt-4">
          <h3>Extras Available</h3>
          <div className="extras-container">
            {extras.map((extra, index) => (
              <div key={index} className="extra-item card">
                <div className="card-body py-2">
                  <h5 className="card-title">
                    <FaCheckCircle className="icon" /> {extra.name}
                  </h5>
                  <p className="card-text">{extra.detail}</p>
                  <p className="price">
                    Price: {extra.price.perRental.display.value}{" "}
                    {extra.price.perRental.display.currency}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center mt-4">
          <button className="book-btn">
            <Link to={links.fullRentalTerms.url} className="navLink2">
              Book Now
            </Link>
          </button>
        </div>
      </section>
    </>
  );
};

export default CarRentalDetails;

// Old code

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import axios from "axios";
// import { FaCar, FaStar } from "react-icons/fa";
// import dayjs from "dayjs";

// const CarRentalDetails = () => {
//   const location = useLocation();
//   const [carRentalDetails, setCarRentalDetails] = useState(null);
//   // const [flightDetailsInfo, setFlightDetailsInfo] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchCarRentalDetails = async () => {
//       const {
//         vehicleId,
//         search_key
//       } = location.state;
//       const options = {
//         method: "GET",
//         url: "https://booking-com15.p.rapidapi.com/api/v1/cars/vehicleDetails",
//         params: {
//             vehicle_id: vehicleId,
//             search_key
//         },
//         headers: {
//           "x-rapidapi-key":
//             "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
//           "x-rapidapi-host": "booking-com15.p.rapidapi.com",
//         },
//       };

//       try {
//         const response = await axios.request(options);
//         console.log(response?.data);
//         setCarRentalDetails(response?.data?.data);
//       } catch (error) {
//         console.error(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCarRentalDetails();
//   }, [location.state]);

// console.log(carRentalDetails, "carRentalDetails")
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!carRentalDetails) {
//     return <div className="">No Rent Car details available</div>;
//   }

//   // Destructure Car Rental details for easier access
//   const {
//     vehicle,
//     meta,
//     depots,
//     whatsIncluded,
//     content,
//     supplier,
//     extras,
//   } = carRentalDetails || {};

// //   console.log(segments, "segments")

//   // Assuming there's only one segment in the response based on your provided data
//   // const segment = segments[0];
//   return (
//     <>
//       <section id="home" className="about-us inner">
//         <div className="container">
//           <div className="about-us-content">
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className="single-about-us">
//                   <div className="about-us-txt">
//                     <h2>Rent Car Details</h2>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-sm-0">
//                 <div className="single-about-us"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className="car-rental-details">
//       {/* Vehicle Information */}
//       <div className="car-details">
//         <h2>{vehicle.label}</h2>
//         <div className="car-info">
//           <img src={vehicle.imageUrl} alt="Car" className="car-image" />
//           <div className="car-specs">
//             <p>{vehicle.makeAndModel}</p>
//             <p>{vehicle.carClass}</p>
//             <p>Transmission: {vehicle.specification.transmission}</p>
//             <p>Air Conditioning: {vehicle.specification.airConditioning ? "Yes" : "No"}</p>
//           </div>
//         </div>
//       </div>

//       {/* Supplier Information */}
//       <div className="supplier-info">
//         <img src={supplier.imageUrl} alt="Supplier Logo" className="supplier-logo" />
//         <div className="supplier-details">
//           <h3>{supplier.name}</h3>
//           <div className="supplier-rating">
//             <FaStar className="star-icon" />
//             <span>{supplier.rating}</span> ({content.carCard.supplier.rating.subtitle})
//           </div>
//           <p>{content.carCard.supplier.rating.title}</p>
//           <p>{content.carCard.supplier.rating.average}</p>
//         </div>
//       </div>

//       {/* Pickup and Drop-off Information */}
//       <div className="depot-info">
//         <div className="pickup-info">
//           <h3>Pickup Location</h3>
//           <p>{depots.pickup.name}</p>
//           <p>{depots.pickup.address}</p>
//         </div>
//         <div className="dropoff-info">
//           <h3>Drop-off Location</h3>
//           <p>{depots.dropoff.name}</p>
//           <p>{depots.dropoff.address}</p>
//         </div>
//       </div>

//       {/* Included Features */}
//       <div className="whats-included">
//         <h3>{whatsIncluded.title}</h3>
//         <ul>
//           {whatsIncluded.items.map((item, index) => (
//             <li key={index}>
//               <FaCar className="tick-icon" />
//               <span>{item.text}</span>
//             </li>
//           ))}
//         </ul>
//       </div>

//       {/* Extras */}
//       <div className="extras">
//         <h3>Extras Available</h3>
//         <ul>
//           {extras.map((extra, index) => (
//             <li key={index}>
//               <strong>{extra.name}</strong>
//               <p>{extra.detail}</p>
//               <p>Price: {extra.price.perRental.display.value} {extra.price.perRental.display.currency}</p>
//             </li>
//           ))}
//         </ul>
//       </div>

//       {/* Important Information */}
//       {/* <div className="important-info">
//         <h3>{content.importantInfo.title}</h3>
//         <p>{content.importantInfo.subtitle}</p>
//         <p dangerouslySetInnerHTML={{ __html: content.importantInfo.items[0].text }} />
//         <a href={meta.links.fullRentalTerms.url} target="_blank" rel="noopener noreferrer">
//           {content.links.fullRentalTerms.label}
//         </a>
//       </div> */}

//       {/* Booking Options */}
//       <div className="booking-options">
//         <button className="book-btn">
//           <Link to="/booking" className="navLink2">
//             Book Now
//           </Link>
//         </button>
//       </div>
//     </section>
//       {/* Booking Section End */}
//     </>
//   );
// };

// export default CarRentalDetails;
