import React, { useState, useEffect } from "react";
import "@dotlottie/player-component";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Tabs, Select, DatePicker, InputNumber, Button, Row, Col } from "antd";
import { Link } from "react-router-dom";
// import { FaAlignCenter } from "react-icons/fa";
import { FaQuoteLeft } from "react-icons/fa6";
import { FaTree, FaBuilding, FaPlane, FaCar } from "react-icons/fa";
import "antd/dist/reset.css"; // Make sure Ant Design styles are included
import axios from "axios";
import moment from "moment";
import { FaArrowRight, FaCalendar, FaSearch, FaClock } from "react-icons/fa";
import { FaLocationPin, FaPerson } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../../config/functions";
import { Newsletter } from "../../config/constants";
import { Post } from "../../config/api/post";
import swal from "sweetalert";

const { TabPane } = Tabs;
const { Option } = Select;

const Home = () => {
  // const navigate = useNavigate();
  // const [active, setActive] = useState("Tours");
  const [loading, setLoading] = useState(false);

  const testimonials = [
    {
      img: ImageUrl("testimonial1.jpg"),
      text: "I usually hate planning vacations, but Travel Fly Hub made it so easy! Their website is user-friendly, and their customer service is fantastic. They helped me find the perfect all-inclusive package for my family to go to the Netherlands, and we had a relaxing and worry-free trip.",
      name: "Chloe K.",
      location: "London, England",
    },
    {
      img: ImageUrl("testimonial2.jpg"),
      text: "My husband and I surprised ourselves with a romantic getaway to China booked through Travel Fly Hub. They found us a charming boutique hotel and suggested the most amazing restaurants and hidden gems. It was the perfect escape!",
      name: "Justin & Lisa F.",
      location: "London, England",
    },
    {
      img: ImageUrl("testimonial1.jpg"),
      text: "I was on a tight budget for my solo backpacking trip, but Travel Fly Hub helped me find the best deals on hostels and transportation. They even recommended some budget-friendly activities that were off the beaten path. I couldn't have done it without them!",
      name: "Oliver A.",
      location: "London, England",
    },
    {
      img: ImageUrl("testimonial1.jpg"),
      text: "Travel Fly Hub found us amazing deals on flights and accommodations for our family trip to Australia. We were able to experience so much more without breaking the bank. We'll definitely be using them again for our next adventure!",
      name: "Kathie B.",
      location: "London, England",
    },
    {
      img: ImageUrl("testimonial2.jpg"),
      text: "Travel Fly Hub saved the day when my original flight got canceled. Their team quickly rebooked me on another flight with minimal disruption to my trip. I was so grateful for their expertise and responsiveness!",
      name: "Stefan H.",
      location: "London, England",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const countDownDate = new Date("Mar 7, 2025 00:00:00").getTime();

  // Initialize state variables for days, hours, minutes, and seconds
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    // Update the countdown every 1 second
    const interval = setInterval(() => {
      // Get the current date and time
      const now = new Date().getTime();

      // Calculate the time remaining
      const distance = countDownDate - now;

      // Calculate days, hours, minutes, and seconds
      const daysRemaining = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hoursRemaining = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutesRemaining = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      const secondsRemaining = Math.floor((distance % (1000 * 60)) / 1000);

      // Update state variables
      setDays(daysRemaining);
      setHours(hoursRemaining);
      setMinutes(minutesRemaining);
      setSeconds(secondsRemaining);

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
      }
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  //For Hotels
  const [searchResults, setSearchResults] = useState([]);
  const [destination, setDestination] = useState("");
  const [destId, setDestId] = useState("");
  const [destType, setDestType] = useState("");
  const [hotels, setHotels] = useState([]);
  const [checkIn, setCheckIn] = useState(moment().format("YYYY-MM-DD"));
  const [checkOut, setCheckOut] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState([]);
  const [rooms, setRooms] = useState(1);
  const [active, setActive] = useState("Hotels");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://booking-com15.p.rapidapi.com/api/v1/hotels/searchDestination",
          {
            params: { query: destination },
            headers: {
              "x-rapidapi-key":
                "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
              "x-rapidapi-host": "booking-com15.p.rapidapi.com",
            },
          }
        );
        console.log(response?.data);
        setSearchResults(response?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (destination) {
      fetchData();
    }
  }, [destination]);

  const handleDestinationSelect = (value) => {
    const selectedDestination = searchResults.find(
      (result) => result.dest_id === value
    );
    if (selectedDestination) {
      setDestination(selectedDestination.label);
      setDestId(selectedDestination.dest_id); // Set dest_id
      setDestType(selectedDestination.dest_type);
    } else {
      setDestination("");
      setDestId(""); // Reset dest_id
      setDestType("");
    }
    setSearchResults([]);
  };

  // const handleDestinationSelect = (value) => {
  //   const selectedDestination = searchResults.find(
  //     (result) => result.dest_id === value
  //   );
  //   setDestination(selectedDestination ? selectedDestination.label : "");
  //   setDestType(selectedDestination ? selectedDestination.dest_type : "");
  //   setSearchResults([])
  // };

  const handleSearch = async () => {
    try {
      const hotelChildren = children.length > 0 ? children.join(",") : "0";
      const response = await axios.get(
        "https://booking-com15.p.rapidapi.com/api/v1/hotels/searchHotels",
        {
          params: {
            dest_id: destId,
            // dest_id: searchResults.find(
            //   (result) => result.label === destination
            // )?.dest_id,
            search_type: destType,
            adults: adults,
            children_age: hotelChildren,
            room_qty: rooms,
            arrival_date: checkIn,
            departure_date: checkOut,
            units: "metric",
            temperature_unit: "c",
            languagecode: "en-us",
            currency_code: "AED",
          },
          headers: {
            "x-rapidapi-key":
              "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
            "x-rapidapi-host": "booking-com15.p.rapidapi.com",
          },
        }
      );
      console.log(response?.data?.data?.hotels);
      // setHotels(response?.data?.data?.hotels);
      console.log(hotels, "hotels");
      navigate("/hotel", { state: response?.data?.data?.hotels });
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddHotelChildAge = () => {
    setChildren([...children, ""]);
  };

  const handleRemoveHotelChildAge = (index) => {
    const updatedHotelAges = [...children];
    updatedHotelAges.splice(index, 1);
    setChildren(updatedHotelAges);
  };

  const handleChangeHotelChildAge = (index, age) => {
    const updatedHotelAges = [...children];
    updatedHotelAges[index] = age;
    setChildren(updatedHotelAges);
  };

  //For Flights
  const [fromFlightDestination, setFromFlightDestination] = useState("");
  const [toFlightDestination, setToFlightDestination] = useState("");
  const [flightDestination, setFlightDestination] = useState([]);
  const [toFlightDetails, setToFlightDetails] = useState([]);
  const [fromAirportId, setFromAirportId] = useState("");
  const [toAirportId, setToAirportId] = useState("");
  const [departDate, setDepartDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [flightAdults, setFlightAdults] = useState(1);
  const [flightChildren, setFlightChildren] = useState(0);
  const [childrenAges, setChildrenAges] = useState([]);
  // const [adults, setAdults] = useState(1);
  const [sortOption, setSortOption] = useState("BEST");
  const [selectedCabinClass, setSelectedCabinClass] = useState("ECONOMY");
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchFlightDestination = async (query, setResults) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.request({
        method: "GET",
        url: "https://booking-com15.p.rapidapi.com/api/v1/flights/searchDestination",
        params: { query },
        headers: {
          "x-rapidapi-key":
            "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
          "x-rapidapi-host": "booking-com15.p.rapidapi.com",
        },
      });
      setResults(response?.data?.data || []);
    } catch (error) {
      setError("Failed to fetch flight destinations.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(flightDestination, "flightDestination");
  console.log(toFlightDetails, "toFlightDetails");

  const handleFlightFromDestinationSelect = (selectedId, name) => {
    console.log(selectedId, "fromAirportId");
    setFromAirportId(selectedId);
    setFromFlightDestination(name);
    setFlightDestination([]);
  };

  const handleFlightToDestinationSelect = (selectedId, name) => {
    console.log(selectedId, "toAirportId");
    setToAirportId(selectedId);
    setToFlightDestination(name);
    setToFlightDetails([]);
  };

  const handleFlightSearch = async () => {
    if (!fromFlightDestination || !toFlightDestination || !departDate) {
      alert("Please select both departure and destination airports.");
      return;
    }

    console.log(
      `Searching flights from ${fromFlightDestination} to ${toFlightDestination}`
    );
    setLoading(true);
    setError(null);

    try {
      const childrenParam =
        childrenAges.length > 0 ? childrenAges.join(",") : "0";

      const response = await axios.request({
        method: "GET",
        url: "https://booking-com15.p.rapidapi.com/api/v1/flights/searchFlights",
        params: {
          fromId: fromAirportId,
          toId: toAirportId,
          departDate: departDate,
          returnDate: returnDate,
          pageNo: "1",
          adults: flightAdults,
          children: childrenParam,
          // children: flightChildren > 0 ? `0,${flightChildren}` : "0",
          sort: sortOption,
          cabinClass: selectedCabinClass,
          // currency_code: "AED",
        },
        headers: {
          "x-rapidapi-key":
            "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
          "x-rapidapi-host": "booking-com15.p.rapidapi.com",
        },
      });
      console.log(response?.data?.data);
      // setHotels(response?.data?.data?.hotels);
      // console.log(hotels, "hotels");
      navigate("/flight", { state: response?.data?.data });
      // handle the response data as needed
    } catch (error) {
      setError("Failed to search flights.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fromFlightDestination) {
      fetchFlightDestination(fromFlightDestination, setFlightDestination);
    }
  }, [fromFlightDestination]);

  useEffect(() => {
    if (toFlightDestination) {
      fetchFlightDestination(toFlightDestination, setToFlightDetails);
    }
  }, [toFlightDestination]);

  const handleAddChildAge = () => {
    setChildrenAges([...childrenAges, ""]);
  };

  const handleRemoveChildAge = (index) => {
    const updatedAges = [...childrenAges];
    updatedAges.splice(index, 1);
    setChildrenAges(updatedAges);
  };

  const handleChangeChildAge = (index, age) => {
    const updatedAges = [...childrenAges];
    updatedAges[index] = age;
    setChildrenAges(updatedAges);
  };

  //For Car Rentals
  const [pickupDestination, setPickupDestination] = useState("");
  const [dropoffDestination, setDropoffDestination] = useState("");
  const [searchPickupCarResults, setSearchPickupCarResults] = useState([]);
  const [searchDropoffCarResults, setSearchDropoffCarResults] = useState([]);
  const [pickupTime, setPickupTime] = useState("");
  const [dropoffTime, setDropoffTime] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [dropoffDate, setDropoffDate] = useState("");
  const [driverAge, setDriverAge] = useState(30);

  const fetchCarDestination = async (query, setResults) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.request({
        method: "GET",
        url: "https://booking-com15.p.rapidapi.com/api/v1/cars/searchDestination",
        params: { query },
        headers: {
          "x-rapidapi-key":
            "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
          "x-rapidapi-host": "booking-com15.p.rapidapi.com",
        },
      });
      console.log(response?.data);
      setResults(response?.data?.data || []);
    } catch (error) {
      setError("Failed to fetch Car destinations.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchLocationSuggestions = async (query) => {
    try {
      const response = await axios.request({
        method: "GET",
        url: "https://booking-com15.p.rapidapi.com/api/v1/cars/searchDestination",
        params: { query },
        headers: {
          "x-rapidapi-key":
            "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
          "x-rapidapi-host": "booking-com15.p.rapidapi.com",
        },
      });
      return response?.data?.data || [];
    } catch (error) {
      console.error("Failed to fetch location suggestions:", error);
      return [];
    }
  };

  console.log(pickupDestination, "pickupDestination");
  console.log(dropoffDestination, "dropoffDestination");

  const handleCarSearch = async () => {
    // Example validation, adjust as per your actual implementation
    if (!pickupDestination || !dropoffDestination) {
      alert("Please select both pickup and drop-off locations.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const pickupLocation = await fetchLocationSuggestions(pickupDestination);
      if (pickupLocation.length === 0 || !pickupLocation[0].coordinates) {
        setError("Invalid pickup location data.");
        console.error("Invalid pickup location data:", pickupLocation);
        return;
      }

      const pickUpLatitude = pickupLocation[0].coordinates.latitude;
      const pickUpLongitude = pickupLocation[0].coordinates.longitude;

      const dropoffLocation = await fetchLocationSuggestions(
        dropoffDestination
      );
      if (dropoffLocation.length === 0 || !dropoffLocation[0].coordinates) {
        setError("Invalid dropoff location data.");
        console.error("Invalid dropoff location data:", dropoffLocation);
        return;
      }

      const dropOffLatitude = dropoffLocation[0].coordinates.latitude;
      const dropOffLongitude = dropoffLocation[0].coordinates.longitude;

      const response = await axios.request({
        method: "GET",
        url: "https://booking-com15.p.rapidapi.com/api/v1/cars/searchCarRentals",
        params: {
          pick_up_latitude: pickUpLatitude,
          pick_up_longitude: pickUpLongitude,
          drop_off_latitude: dropOffLatitude,
          drop_off_longitude: dropOffLongitude,
          pick_up_date: pickupDate,
          drop_off_date: dropoffDate,
          pick_up_time: pickupTime,
          drop_off_time: dropoffTime,
          driver_age: driverAge,
          // currency_code: "USD",
        },
        headers: {
          "x-rapidapi-key":
            "e2cd1ec714msha7cc64f965a076bp1d6dcejsnc4acca13457b",
          "x-rapidapi-host": "booking-com15.p.rapidapi.com",
        },
      });

      console.log(response?.data);
      // Handle the response data as needed
      navigate("/carRental", { state: response?.data?.data });
    } catch (error) {
      setError("Failed to search car rentals.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePickupDestinationSelect = (selectedId) => {
    console.log(`Selected location ID: ${selectedId}`);
    setPickupDestination(selectedId);
    setSearchPickupCarResults([]); // Adjust this as necessary
  };

  const handleDropoffDestinationSelect = (selectedId) => {
    console.log(`Selected location ID: ${selectedId}`);
    setDropoffDestination(selectedId);
    setSearchDropoffCarResults([]); // Adjust this as necessary
  };

  useEffect(() => {
    // Example: Fetch car rental destinations when pickupDestination changes
    if (pickupDestination) {
      fetchCarDestination(pickupDestination, setSearchPickupCarResults);
    }
  }, [pickupDestination]);

  useEffect(() => {
    // Example: Fetch car rental destinations when pickupDestination changes
    if (dropoffDestination) {
      fetchCarDestination(dropoffDestination, setSearchDropoffCarResults);
    }
  }, [dropoffDestination]);

  //Newsletter

  const [email, setEmail] = useState("");

  // const handleSubscribe = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await axios.post('http://localhost:3026/api/newsletter', { email });
  //     alert('Subscription Successfully!');
  //     setEmail('');
  //   } catch (error) {
  //     console.error('Subscription error:', error);
  //     alert('Subscription failed. Please try again later.');
  //   }
  // };

  const handleSubscribe = (e) => {
    e.preventDefault();

    Post(Newsletter.newsletter, { email })
      .then((response) => {
        if (response) {
          swal("Success", response?.message, "success");
          setEmail("");
        } else {
          swal(
            "Oops!",
            response?.message || response?.response?.message,
            "error"
          );
        }
        // alert('Subscription Successfully!');
      })
      .catch((error) => {
        console.error("Subscription error:", error);
        swal("Oops!", "internal server error", "error");
      });
  };

  const handleReadMore = (image, details) => {
    navigate('/blogDetails', { state: { image, details } });
  };

  return (
    <>
      {/* <div className="container mx-auto px-6 pt-32">
        <div className="relative rounded-2xl lg:pb-32 lg:h-[70vh]">
          <div className="overfow-hidden h-full w-full relative">
            <img
              src=ImageUrl("b1.jpg"
         )     alt="b1-img"
              className="lg:absolute top-0 w-full lg:h-[70vh] h-30rem object-cover rounded-2xl hoverImg"
            />
          </div>
          <div className="absolute top-0 bottom-0 left-0 right-0 h-fit">
            <h1 className="text-white text-4xl mt-16 flex justify-center font-bold lg:w-4/5 mx-auto text-center">
              Explore the whole world and enjoy its beauty!
            </h1>
            <p className="text-white flex justify-center mt-4 px-6 text-center">
              Find and write about your experiences around the world
            </p>
          </div>
          <div className="lg:w-4/5 lg-h-[70vh] mx-auto h-full">
            <div className="bg-white px-8 py-8 rounded-[14px] lg:absolute bottom-16 lg:w-4/5 shadow-lg">
              <div className="lg:flex gap-4 gap-x-2">
                <div className="flex flex-wrap gap-x-16 w-full">
                  <button
                    onClick={() => setActive("Tours")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Tours"
                        ? "text-black border-b-2 border-black border-solid"
                        : "text-black"
                    }`}
                  >
                    Tours
                  </button>
                  <button
                    onClick={() => setActive("Hotels")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Hotels"
                        ? "text-black border-b-2 border-black border-solid"
                        : "text-black"
                    }`}
                  >
                    Hotels
                  </button>
                  <button
                    onClick={() => setActive("Flights")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Flights"
                        ? "text-black border-b-2 border-black border-solid"
                        : "text-black"
                    }`}
                  >
                    Flights
                  </button>
                  <button
                    onClick={() => setActive("Transport")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Transport"
                        ? "text-black border-b-2 border-black border-solid"
                        : "text-black"
                    }`}
                  >
                    Transport
                  </button>
                </div>
                <button className="flex items-center gap-2 whitespace-pre bg-transparent text-light border-none outline-none">
                  Last Searching <FaArrowRight />
                </button>
              </div>
              <div className="flex flex-wrap items-end gap-4 justify-between border-t border-solid py-4">
                <div>
                  <p className="text-black text-lg">Destination</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaLocationPin
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="text"
                      placeholder="Ball Indonesia"
                      className="bg-light1 rounded-md pl-6 text-sm absolute h-full w-full outline-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Check In</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaCalendar
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="date"
                      defaultValue="2022-02-12"
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Check Out</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaCalendar
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="date"
                      defaultValue="2022-02-12"
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Adults/Children</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaPerson
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="text"
                      placeholder="1 Room, 2 Guest"
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div className="h-10 w-40 flex items-center justify-center">
                  <button onClick={() => navigate("/booking")} class="book-btn">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="container mx-auto px-6 pt-32">
        <div className="relative rounded-2xl lg:pb-32 lg:h-[70vh]">
          <div className="overfow-hidden h-full w-full relative">
            <img
              src="assets/images/b1.jpg"
              alt="b1-img"
              className="lg:absolute top-0 w-full lg:h-[70vh] h-30rem object-cover rounded-2xl hoverImg"
            />
          </div>
          <div className="absolute top-0 bottom-0 left-0 right-0 h-fit">
            <h1 className="text-white text-4xl mt-16 flex justify-center font-bold lg:w-4/5 mx-auto text-center">
              Explore the whole world and enjoy its beauty!
            </h1>
            <p className="text-white flex justify-center mt-4 px-6 text-center">
              Find and write about your experiences around the world
            </p>
          </div>
          <div className="lg:w-4/5 lg-h-[70vh] mx-auto h-full">
            <div className="bg-white px-8 py-8 rounded-[14px] lg:absolute bottom-16 lg:w-4/5 shadow-lg">
              <div className="lg:flex gap-4 gap-x-2">
                <div className="flex flex-wrap gap-x-16 w-full">
                  <button
                    onClick={() => setActive("Tours")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Tours"
                        ? "text-black border-b-2 border-black border-solid"
                        : "text-black"
                    }`}
                  >
                    Tours
                  </button>
                  <button
                    onClick={() => setActive("Hotels")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Hotels"
                        ? "text-black border-b-2 border-black border-solid"
                        : "text-black"
                    }`}
                  >
                    Hotels
                  </button>
                  <button
                    onClick={() => setActive("Flights")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Flights"
                        ? "text-black border-b-2 border-black border-solid"
                        : "text-black"
                    }`}
                  >
                    Flights
                  </button>
                  <button
                    onClick={() => setActive("Transport")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Transport"
                        ? "text-black border-b-2 border-black border-solid"
                        : "text-black"
                    }`}
                  >
                    Transport
                  </button>
                </div>
                <button className="flex items-center gap-2 whitespace-pre bg-transparent text-light border-none outline-none">
                  Last Searching <FaArrowRight />
                </button>
              </div>
              <div className="flex flex-wrap items-end gap-4 justify-between border-t border-solid py-4">
                <div>
                  <p className="text-black text-lg">Destination</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaLocationPin
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="text"
                      value={destination}
                      onChange={(e) => setDestination(e.target.value)}
                      className="bg-light1 rounded-md pl-6 text-sm absolute h-full w-full outline-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Check In</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaCalendar
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="date"
                      value={checkIn}
                      onChange={(e) => setCheckIn(e.target.value)}
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Check Out</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaCalendar
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="date"
                      value={checkOut}
                      onChange={(e) => setCheckOut(e.target.value)}
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Adults/Children</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaPerson
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="text"
                      value={`${adults} Adults, ${children} Children`}
                      onChange={(e) => {
                        const [adultsInput, childrenInput] = e.target.value.split(", ");
                        setAdults(parseInt(adultsInput.split(' ')[0]));
                        setChildren(parseInt(childrenInput.split(' ')[0]));
                      }}
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Rooms</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <input
                      type="number"
                      min="1"
                      value={rooms}
                      onChange={(e) => setRooms(e.target.value)}
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div className="h-10 w-40 flex items-center justify-center">
                  <button onClick={handleSearch} className="book-btn">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container mx-auto px-6 pt-32">
        <div className="relative rounded-2xl lg:pb-32 lg:h-[132vh] xl:h-[110vh] 2xl:h-[100vh]">
          <div className="overfow-hidden h-full w-full relative">
            <img
              src={ImageUrl("blogdetails.png")}
              alt="b1-img"
              className="lg:absolute top-0 w-full h-[32vh] sm:h-[35vh] lg:h-[70vh] h-30rem object-cover rounded-2xl hoverImg"
            />
          </div>
          <div className="absolute top-0 bottom-0 left-0 right-0 h-fit">
            <h1 className="text-white text-lg sm:text-2xl lg:text-4xl mt-16 flex justify-center font-bold lg:w-4/5 mx-auto text-center">
              Explore the whole world and enjoy its beauty!
            </h1>
            <p className="text-white flex justify-center mt-4 px-6 text-center">
              Find and write about your experiences around the world
            </p>
          </div>
          <div className="lg:w-4/5 lg-h-[70vh] mx-auto h-full">
            <div className="bg-white px-8 py-8 rounded-[14px] lg:absolute bottom-16 lg:w-4/5 shadow-lg">
              <div className="lg:flex gap-4 gap-x-2">
                <div className="flex flex-wrap gap-x-9 sm:gap-x-16 w-full">
                  <button
                    onClick={() => setActive("Hotels")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Hotels"
                        ? "text-black text-lg font-semibold border-b-2 border-black border-solid"
                        : "text-black text-lg font-semibold"
                    }`}
                  >
                    Hotels
                  </button>
                  <button
                    onClick={() => setActive("Flights")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Flights"
                        ? "text-black text-lg font-semibold border-b-2 border-black border-solid"
                        : "text-black text-lg font-semibold"
                    }`}
                  >
                    Flights
                  </button>
                  <button
                    onClick={() => setActive("Car Rentals")}
                    className={`rounded-sm flex gap-x-2 items-center focus:outline-none h-14 text-base cursor-pointer bg-transparent whitespace-pre ${
                      active === "Car Rentals"
                        ? "text-black text-lg font-semibold border-b-2 border-black border-solid"
                        : "text-black text-lg font-semibold"
                    }`}
                  >
                    Car Rentals
                  </button>
                </div>
                <button className="flex items-center gap-2 whitespace-pre bg-transparent text-light border-none outline-none">
                  Last Searching <FaArrowRight />
                </button>
              </div>
              {/* {active === "Hotels" && (
                <>
                  <div className="flex flex-wrap items-end gap-4 justify-between border-t border-solid py-4">
                    <div>
                      <p className="text-black text-lg">Destination</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaLocationPin
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="text"
                          value={destination}
                          onChange={(e) => setDestination(e.target.value)}
                          placeholder="Enter City Name"
                          className="bg-light1 rounded-md pl-6 text-sm absolute h-full w-full outline-none"
                        />
                        {searchResults.length > 0 && (
                          <select
                            className="absolute bg-white border rounded-md mt-12 w-full"
                            onChange={(e) =>
                              handleDestinationSelect(e.target.value)
                            }
                          >
                            {searchResults.map((result) => (
                              <option
                                key={result.dest_id}
                                value={result.dest_id}
                              >
                                {result.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">Check In</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaCalendar
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="date"
                          value={checkIn}
                          onChange={(e) => setCheckIn(e.target.value)}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">Check Out</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaCalendar
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="date"
                          value={checkOut}
                          onChange={(e) => setCheckOut(e.target.value)}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">Guests</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaPerson
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="text"
                          value={`${adults} Adults, ${children} Children`}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const [adultsInput, childrenInput] =
                              inputValue.split(", ");
                            setAdults(parseInt(adultsInput.split(" ")[0]) || 0);
                            setChildren(
                              parseInt(childrenInput.split(" ")[0]) || 0
                            );
                          }}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">Rooms</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <input
                          type="number"
                          min="1"
                          value={rooms}
                          onChange={(e) => setRooms(e.target.value)}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div className="h-10 w-40 flex items-center justify-center">
                      <button onClick={handleSearch} className="book-btn">
                        Search
                      </button>
                    </div>
                  </div>
                </>
              )} */}
              {active === "Hotels" && (
                <>
                  <div className="flex flex-wrap items-end gap-4 justify-between border-t border-solid py-4">
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg font-semibold mb-2">
                        Destination
                      </p>
                      <div className="relative flex items-center">
                        <FaLocationPin
                          size={20}
                          className="absolute ml-2 text-gray-600"
                        />
                        <input
                          type="text"
                          value={destination}
                          onChange={(e) => setDestination(e.target.value)}
                          placeholder="Enter City Name"
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                        {searchResults.length > 0 && (
                          <div className="absolute top-full mt-2 w-full bg-white border rounded-md shadow-lg z-10">
                            {searchResults.map((result) => (
                              <div
                                key={result.dest_id}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() =>
                                  handleDestinationSelect(result.dest_id)
                                }
                              >
                                {result.label}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg font-semibold mb-2">
                        Check In
                      </p>
                      <div className="relative flex items-center">
                        <FaCalendar
                          size={20}
                          className="absolute ml-2 text-gray-600"
                        />
                        <input
                          type="date"
                          value={checkIn}
                          onChange={(e) => setCheckIn(e.target.value)}
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg font-semibold mb-2">
                        Check Out
                      </p>
                      <div className="relative flex items-center">
                        <FaCalendar
                          size={20}
                          className="absolute ml-2 text-gray-600"
                        />
                        <input
                          type="date"
                          value={checkOut}
                          onChange={(e) => setCheckOut(e.target.value)}
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col flight-input-container">
                      <label className="text-lg font-semibold text-gray-800 mb-1">
                        Children Ages(Optional)
                      </label>
                      {children.map((age, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-2 mt-1"
                        >
                          <input
                            type="number"
                            value={age}
                            onChange={(e) =>
                              handleChangeHotelChildAge(index, e.target.value)
                            }
                            placeholder={`Child ${index + 1} Age`}
                            className="bg-gray-100 rounded-md py-2 pl-2 text-sm outline-none w-24"
                          />
                          <button
                            onClick={() => handleRemoveHotelChildAge(index)}
                            className="bg-gradient-to-r from-red-400 to-red-900 text-white py-2 px-4 rounded-md"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        onClick={handleAddHotelChildAge}
                        className="bg-[#49949C] text-white py-2 px-4 mt-2 rounded-md"
                      >
                        Add Child Age
                      </button>
                    </div>
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg font-semibold mb-2">
                        Adults (Optional)
                      </p>
                      <div className="relative flex items-center">
                        <input
                          type="number"
                          min="1"
                          value={flightAdults}
                          onChange={(e) => setFlightAdults(e.target.value)}
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                      </div>
                    </div>
                    {/* <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg mb-2">Guests</p>
                      <div className="relative flex items-center">
                        <FaPerson
                          size={20}
                          className="absolute ml-2 text-gray-600"
                        />
                        <input
                          type="text"
                          value={`${adults} Adults, ${children} Children`}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const [adultsInput, childrenInput] =
                              inputValue.split(", ");
                            setAdults(parseInt(adultsInput.split(" ")[0]) || 0);
                            setChildren(
                              parseInt(childrenInput.split(" ")[0]) || 0
                            );
                          }}
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                      </div>
                    </div> */}
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg font-semibold mb-2">
                        Rooms (Optional)
                      </p>
                      <div className="relative flex items-center">
                        <input
                          type="number"
                          min="1"
                          value={rooms}
                          onChange={(e) => setRooms(e.target.value)}
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                      </div>
                    </div>
                    <div className="w-full lg:h-10 lg:w-40 flex items-center justify-center">
                      <button onClick={handleSearch} className="book-btn">
                        Search
                      </button>
                    </div>
                    {/* <div className="w-full lg:w-1/3 flex items-center justify-center lg:justify-start mt-4">
        <button onClick={handleSearch} className="book-btn bg-gradient-to-r from-blue-400 to-blue-900 text-white py-2 px-6 rounded-md shadow-md hover:from-blue-500 hover:to-blue-700 transition duration-300">
          Search
        </button>
      </div> */}
                  </div>
                </>
              )}

              {/* {active === "Flights" && (
                <>
                  <div className="flex flex-wrap items-end gap-4 justify-between border-t border-solid py-4">
                    <div>
                      <p className="text-black text-lg">From</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaLocationPin
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="text"
                          value={fromFlightDestination}
                          onChange={(e) =>
                            setFromFlightDestination(e.target.value)
                          }
                          placeholder="Enter Airport Name"
                          className="bg-light1 rounded-md pl-6 text-sm absolute h-full w-full outline-none"
                        />
                        {flightDestination.length > 0 && (
                          <select
                            className="absolute bg-white border rounded-md mt-12 w-full"
                            onChange={(e) =>
                              handleFlightFromDestinationSelect(
                                e.target.value,
                                "from"
                              )
                            }
                          >
                            {flightDestination.map((destination) => (
                              // <option key={result.id} value={result.id}>
                              //   {result.name} ({result.code})
                              // </option>
                              <option
                                key={destination.id}
                                value={destination.id}
                              >
                                {destination.name}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">To</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaLocationPin
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="text"
                          value={toFlightDestination}
                          onChange={(e) =>
                            setToFlightDestination(e.target.value)
                          }
                          placeholder="Enter Airport Name"
                          className="bg-light1 rounded-md pl-6 text-sm absolute h-full w-full outline-none"
                        />
                        {toFlightDetails.length > 0 && (
                          <select
                            className="absolute bg-white border rounded-md mt-12 w-full"
                            onChange={(e) =>
                              handleFlightToDestinationSelect(
                                e.target.value,
                                "to"
                              )
                            }
                          >
                            {toFlightDetails.map((destination) => (
                              <option
                                key={destination.id}
                                value={destination.id}
                              >
                                {destination.name}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">Depart Date</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaCalendar
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="date"
                          value={departDate}
                          onChange={(e) => setDepartDate(e.target.value)}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">Return Date</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaCalendar
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="date"
                          value={returnDate}
                          onChange={(e) => setReturnDate(e.target.value)}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label className="text-lg text-gray-800 mb-1">Children Ages</label>
                      {childrenAges.map((age, index) => (
                        <div key={index} className="flex items-center gap-2 mt-1">
                          <input
                            type="number"
                            value={age}
                            onChange={(e) => handleChangeChildAge(index, e.target.value)}
                            placeholder={`Child ${index + 1} Age`}
                            className="bg-gray-100 rounded-md py-2 pl-2 text-sm outline-none w-24"
                          />
                          <button
                            onClick={() => handleRemoveChildAge(index)}
                            className="bg-gradient-to-r from-red-400 to-red-900 text-white py-2 px-4 rounded-md"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        onClick={handleAddChildAge}
                        className="bg-[#49949C] text-white py-2 px-4 mt-2 rounded-md"
                      >
                        Add Child Age
                      </button>
                    </div>
                    <div className="flex flex-col col-span-2 lg:col-span-1">
                      <label className="text-lg font-semibold mb-2">Sort</label>
                      <select
                        value={sortOption}
                        onChange={(e) => setSortOption(e.target.value)}
                        className="bg-light1 rounded-md text-sm h-10 w-40 pl-6 outline-none"
                      >
                        <option value="BEST">BEST</option>
                        <option value="CHEAPEST">CHEAPEST</option>
                        <option value="FASTEST">FASTEST</option>
                      </select>
                    </div>

                    <div className="flex flex-col col-span-2 lg:col-span-1">
                      <label className="text-lg font-semibold mb-2">Cabin Class</label>
                      <select
                        value={selectedCabinClass}
                        onChange={(e) => setSelectedCabinClass(e.target.value)}
                        className="bg-light1 rounded-md text-sm h-10 w-40 pl-6 outline-none"
                      >
                        <option value="ECONOMY">ECONOMY</option>
                        <option value="PREMIUM_ECONOMY">PREMIUM_ECONOMY</option>
                        <option value="BUSINESS">BUSINESS</option>
                        <option value="FIRST">FIRST</option>
                      </select>
                    </div>
                    <div className="h-10 w-40 flex items-center justify-center">
                      <button onClick={handleFlightSearch} className="book-btn">
                        Search
                      </button>
                    </div>
                  </div>
                </>
              )} */}

              {active === "Flights" && (
                <>
                  <div className="flex flex-wrap items-end gap-4 justify-between border-t border-solid py-4">
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg font-semibold mb-2">
                        From
                      </p>
                      <div className="relative flex items-center">
                        <FaLocationPin
                          size={20}
                          className="absolute ml-2 text-gray-600"
                        />
                        <input
                          type="text"
                          value={fromFlightDestination}
                          onChange={(e) =>
                            setFromFlightDestination(e.target.value)
                          }
                          placeholder="Enter Country Name"
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                        {flightDestination.length > 0 && (
                          <div className="absolute top-full mt-2 w-full bg-white border rounded-md shadow-lg z-10">
                            {flightDestination.map((destination) => (
                              <div
                                key={destination.id}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() =>
                                  handleFlightFromDestinationSelect(
                                    destination.id,
                                    destination.name
                                  )
                                }
                              >
                                {destination.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg font-semibold mb-2">
                        To
                      </p>
                      <div className="relative flex items-center">
                        <FaLocationPin
                          size={20}
                          className="absolute ml-2 text-gray-600"
                        />
                        <input
                          type="text"
                          value={toFlightDestination}
                          onChange={(e) =>
                            setToFlightDestination(e.target.value)
                          }
                          placeholder="Enter Country Name"
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                        {toFlightDetails.length > 0 && (
                          <div className="absolute top-full mt-2 w-full bg-white border rounded-md shadow-lg z-10">
                            {toFlightDetails.map((destination) => (
                              <div
                                key={destination.id}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() =>
                                  handleFlightToDestinationSelect(
                                    destination.id,
                                    destination.name
                                  )
                                }
                              >
                                {destination.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg font-semibold text-md mb-2">
                        Depart Date
                      </p>
                      <div className="relative flex items-center">
                        <FaCalendar
                          size={20}
                          className="absolute ml-2 text-gray-600"
                        />
                        <input
                          type="date"
                          value={departDate}
                          onChange={(e) => setDepartDate(e.target.value)}
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg font-semibold mb-2">
                        Return Date
                      </p>
                      <div className="relative flex items-center">
                        <FaCalendar
                          size={20}
                          className="absolute ml-2 text-gray-600"
                        />
                        <input
                          type="date"
                          value={returnDate}
                          onChange={(e) => setReturnDate(e.target.value)}
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col flight-input-container">
                      <label className="text-lg text-gray-800 mb-1 font-semibold">
                        Children Ages(Optional)
                      </label>
                      {childrenAges.map((age, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-2 mt-1"
                        >
                          <input
                            type="number"
                            value={age}
                            onChange={(e) =>
                              handleChangeChildAge(index, e.target.value)
                            }
                            placeholder={`Child ${index + 1} Age`}
                            className="bg-gray-100 rounded-md py-2 pl-2 text-sm outline-none w-24"
                          />
                          <button
                            onClick={() => handleRemoveChildAge(index)}
                            className="bg-gradient-to-r from-red-400 to-red-900 text-white py-2 px-4 rounded-md"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        onClick={handleAddChildAge}
                        className="bg-[#49949C] text-white py-2 px-4 mt-2 rounded-md"
                      >
                        Add Child Age
                      </button>
                    </div>
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <p className="text-black text-lg font-semibold mb-2">
                        Adults (Optional)
                      </p>
                      <div className="relative flex items-center">
                        <input
                          type="number"
                          min="1"
                          value={adults}
                          onChange={(e) => setAdults(e.target.value)}
                          className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <label className="text-lg font-semibold mb-2">
                        Sort (Optional)
                      </label>
                      <select
                        value={sortOption}
                        onChange={(e) => setSortOption(e.target.value)}
                        className="bg-gray-100 rounded-md text-sm h-10 w-full pl-6 outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                      >
                        <option value="BEST">BEST</option>
                        <option value="CHEAPEST">CHEAPEST</option>
                        <option value="FASTEST">FASTEST</option>
                      </select>
                    </div>
                    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5">
                      <label className="text-lg font-semibold mb-2">
                        Cabin Class (Optional)
                      </label>
                      <select
                        value={selectedCabinClass}
                        onChange={(e) => setSelectedCabinClass(e.target.value)}
                        className="bg-gray-100 rounded-md text-sm h-10 w-full pl-6 outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                      >
                        <option value="ECONOMY">ECONOMY</option>
                        <option value="PREMIUM_ECONOMY">PREMIUM_ECONOMY</option>
                        <option value="BUSINESS">BUSINESS</option>
                        <option value="FIRST">FIRST</option>
                      </select>
                    </div>
                    <div className="w-full lg:h-10 lg:w-40 flex items-center justify-center">
                      <button onClick={handleFlightSearch} className="book-btn">
                        Search
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* {active === "Flights" && (
                <>
                  <div className="flex flex-wrap items-end gap-4 justify-between border-t border-solid py-4">
                    <div>
                      <p className="text-black text-lg">From</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaLocationPin
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="text"
                          value={flightDestinationId}
                          onChange={(e) => setFlightDestinationId(e.target.value)}
                          placeholder="Enter Airport Name"
                          className="bg-light1 rounded-md pl-6 text-sm absolute h-full w-full outline-none"
                        />
                        {flightDestination.length > 0 && (
                          <select
                            className="absolute bg-white border rounded-md mt-12 w-full"
                            onChange={(e) =>
                              handleFlightDestinationSelect(e.target.value)
                            }
                          >
                            {flightDestination.map((result) => (
                              <option
                                key={result.id}
                                value={result.id}
                              >
                                {result.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">To</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaLocationPin
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="text"
                          value={toFlightDestination}
                          onChange={(e) => setToFlightDestination(e.target.value)}
                          placeholder="Enter Airport Name"
                          className="bg-light1 rounded-md pl-6 text-sm absolute h-full w-full outline-none"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">Depart Date</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaCalendar
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="date"
                          value={departDate}
                          onChange={(e) => setDepartDate(e.target.value)}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">Return Date</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaCalendar
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="date"
                          value={returnDate}
                          onChange={(e) => setReturnDate(e.target.value)}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">Guests</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaPerson
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="text"
                          value={`${flightAdults} Adults, ${flightChildren} Children`}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const [adultsInput, childrenInput] =
                              inputValue.split(", ");
                            setFlightAdults(parseInt(adultsInput.split(" ")[0]) || 0);
                            setFlightChildren(
                              parseInt(childrenInput.split(" ")[0]) || 0
                            );
                          }}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">Sort</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <select
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        >
                          <option value="1">Option 1</option>
                          <option value="2">Option 2</option>
                          <option value="3">Option 3</option>
                        </select>
                      </div>
                    </div>
                    <div className="h-10 w-40 flex items-center justify-center">
                      <button onClick={handleFlightSearch} className="book-btn">
                        Search
                      </button>
                    </div>
                  </div>
                </>
              )} */}
              {active === "Car Rentals" && (
                <div className="flex flex-wrap items-end gap-4 justify-between border-t border-solid py-4">
                  <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4 md:mb-0">
                    <p className="text-black text-lg font-semibold mb-2">
                      PickUp Location
                    </p>
                    <div className="relative flex items-center">
                      <FaLocationPin
                        size={20}
                        className="absolute ml-2 text-gray-600"
                      />
                      <input
                        type="text"
                        value={pickupDestination}
                        onChange={(e) => setPickupDestination(e.target.value)}
                        placeholder="Enter City Name"
                        className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                      />
                      {searchPickupCarResults.length > 0 && (
                        <div className="absolute top-full mt-2 w-full bg-white border rounded-md shadow-lg z-10">
                          {searchPickupCarResults.map((result) => (
                            <div
                              key={result.city}
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                handlePickupDestinationSelect(result.name)
                              }
                            >
                              {result.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4 md:mb-0">
                    <p className="text-black text-lg font-semibold mb-2">
                      DropOff Location
                    </p>
                    <div className="relative flex items-center">
                      <FaLocationPin
                        size={20}
                        className="absolute ml-2 text-gray-600"
                      />
                      <input
                        type="text"
                        value={dropoffDestination}
                        onChange={(e) => setDropoffDestination(e.target.value)}
                        placeholder="Enter City Name"
                        className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                      />
                      {searchDropoffCarResults.length > 0 && (
                        <div className="absolute top-full mt-2 w-full bg-white border rounded-md shadow-lg z-10">
                          {searchDropoffCarResults.map((result) => (
                            <div
                              key={result.city}
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                handleDropoffDestinationSelect(result.name)
                              }
                            >
                              {result.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4 md:mb-0">
                    <p className="text-black text-lg font-semibold mb-2">
                      PickUp Date
                    </p>
                    <div className="relative flex items-center">
                      <FaCalendar
                        size={20}
                        className="absolute ml-2 text-gray-600"
                      />
                      <input
                        type="date"
                        value={pickupDate}
                        onChange={(e) => setPickupDate(e.target.value)}
                        className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4 md:mb-0">
                    <p className="text-black text-lg font-semibold mb-2">
                      DropOff Date
                    </p>
                    <div className="relative flex items-center">
                      <FaCalendar
                        size={20}
                        className="absolute ml-2 text-gray-600"
                      />
                      <input
                        type="date"
                        value={dropoffDate}
                        onChange={(e) => setDropoffDate(e.target.value)}
                        className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4 md:mb-0">
                    <p className="text-black text-lg font-semibold mb-2">
                      PickUp Time
                    </p>
                    <div className="relative flex items-center">
                      <FaClock
                        size={20}
                        className="absolute ml-2 text-gray-600"
                      />
                      <input
                        type="time"
                        value={pickupTime}
                        onChange={(e) => setPickupTime(e.target.value)}
                        className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4 md:mb-0">
                    <p className="text-black text-lg font-semibold mb-2">
                      DropOff Time
                    </p>
                    <div className="relative flex items-center">
                      <FaClock
                        size={20}
                        className="absolute ml-2 text-gray-600"
                      />
                      <input
                        type="time"
                        value={dropoffTime}
                        onChange={(e) => setDropoffTime(e.target.value)}
                        className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4 md:mb-0">
                    <p className="text-black text-lg font-semibold mb-2">
                      Driver Age (Optional)
                    </p>
                    <div className="relative flex items-center">
                      <input
                        type="number"
                        min="1"
                        value={driverAge}
                        onChange={(e) => setDriverAge(e.target.value)}
                        className="bg-gray-100 rounded-md pl-10 pr-4 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:h-10 lg:w-40 flex items-center justify-center">
                    <button onClick={handleCarSearch} className="book-btn">
                      Search
                    </button>
                  </div>
                  {/* <div className="w-full flex items-center justify-center">
                    <button
                      onClick={handleCarSearch}
                      className="book-btn"
                      // className="bg-blue-500 text-white rounded-md py-2 px-6 text-sm w-full md:w-auto hover:bg-blue-600 transition duration-300"
                    >
                      Search
                    </button>
                  </div> */}
                </div>
              )}
              {/* {active === "Car Rentals" && (
                <>
                  <div className="flex flex-wrap items-end gap-4 justify-between border-t border-solid py-4">
                    <div>
                      <p className="text-black text-lg">PickUp Location</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaLocationPin
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="text"
                          value={destination}
                          onChange={(e) => setDestination(e.target.value)}
                          className="bg-gray-100 rounded-md pl-6 pr-12 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                        {searchResults.length > 0 && (
                          <select
                            className="absolute bg-white border rounded-md mt-12 w-full shadow-lg z-10"
                            onChange={(e) =>
                              handleDestinationSelect(e.target.value)
                            }
                          >
                            {searchResults.map((result) => (
                              <option
                                key={result.dest_id}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                value={result.dest_id}
                              >
                                {result.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">DropOff Location</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaLocationPin
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="text"
                          value={destination}
                          onChange={(e) => setDestination(e.target.value)}
                          className="bg-gray-100 rounded-md pl-6 pr-12 py-2 text-sm w-full outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
                        />
                        {searchResults.length > 0 && (
                          <select
                            className="absolute bg-white border rounded-md mt-12 w-full shadow-lg z-10"
                            onChange={(e) =>
                              handleDestinationSelect(e.target.value)
                            }
                          >
                            {searchResults.map((result) => (
                              <option
                                key={result.dest_id}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                value={result.dest_id}
                              >
                                {result.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">PickUp Date</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaCalendar
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="date"
                          value={checkIn}
                          onChange={(e) => setCheckIn(e.target.value)}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-black text-lg">DropOff Date</p>
                      <div className="relative h-10 w-40 flex items-center">
                        <FaCalendar
                          size={12}
                          className="absolute top-0 bottom-0 z-10 h-full"
                        />
                        <input
                          type="date"
                          value={checkOut}
                          onChange={(e) => setCheckOut(e.target.value)}
                          className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                        />
                      </div>
                    </div>
                    <div className="h-10 w-40 flex items-center justify-center">
                      <button onClick={handleSearch} className="book-btn">
                        Search
                      </button>
                    </div>
                  </div>
                </>
              )} */}
              {/* <div className="flex flex-wrap items-end gap-4 justify-between border-t border-solid py-4">
                <div>
                  <p className="text-black text-lg">Destination</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaLocationPin
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="text"
                      value={destination}
                      onChange={(e) => setDestination(e.target.value)}
                      className="bg-light1 rounded-md pl-6 text-sm absolute h-full w-full outline-none"
                    />
                    {searchResults.length > 0 && (
                      <select
                        className="absolute bg-white border rounded-md mt-12 w-full"
                        onChange={(e) =>
                          handleDestinationSelect(e.target.value)
                        }
                      >
                        {searchResults.map((result) => (
                          <option key={result.dest_id} value={result.dest_id}>
                            {result.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Check In</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaCalendar
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="date"
                      value={checkIn}
                      onChange={(e) => setCheckIn(e.target.value)}
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Check Out</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaCalendar
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="date"
                      value={checkOut}
                      onChange={(e) => setCheckOut(e.target.value)}
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Guests</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <FaPerson
                      size={12}
                      className="absolute top-0 bottom-0 z-10 h-full"
                    />
                    <input
                      type="text"
                      value={`${adults} Adults, ${children} Children`}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const [adultsInput, childrenInput] =
                          inputValue.split(", ");
                        setAdults(parseInt(adultsInput.split(" ")[0]) || 0);
                        setChildren(parseInt(childrenInput.split(" ")[0]) || 0);
                      }}
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-black text-lg">Rooms</p>
                  <div className="relative h-10 w-40 flex items-center">
                    <input
                      type="number"
                      min="1"
                      value={rooms}
                      onChange={(e) => setRooms(e.target.value)}
                      className="bg-light1 rounded-md text-sm absolute h-full w-full pl-6 outline-none"
                    />
                  </div>
                </div>
                <div className="h-10 w-40 flex items-center justify-center">
                  <button onClick={handleSearch} className="book-btn">
                    Search
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* service start */}
      <section id="service" className="service">
        <div className="container">
          <div className="service-counter text-center">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="single-service-box">
                  <div className="statistics-img">
                    <dotlottie-player
                      src="https://lottie.host/f468d04a-dd68-4700-abeb-4e80f4cef8d0/UssScqypXj.json"
                      background="transparent"
                      speed="1"
                      // className="animationhw"
                      style={{ height: "105px", width: "105px" }}
                      loop
                      autoplay
                    />
                  </div>
                  <div className="service-content">
                    <h2>
                      <Link> online flight booking </Link>
                    </h2>
                    <p>
                      Find your perfect flight & take off on adventurous
                      journeys.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="single-service-box">
                  <div className="service-img">
                    {/* <img src="assets/images/s2.png" alt="service-icon" />  */}
                    <script
                      src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs"
                      type="module"
                    ></script>
                    <dotlottie-player
                      src="https://lottie.host/385194ea-a017-46e9-aad3-9fa0f4666aff/hdMFneD0kY.json"
                      background="transparent"
                      speed="1"
                      // className="animationhw"
                      style={{ height: "80px", width: "95px" }}
                      loop
                      autoplay
                    />
                  </div>
                  <div className="service-content">
                    <h2>
                      <Link> book top className hotel </Link>
                    </h2>
                    <p>Book your dream hotel stay in an instant.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="single-service-box">
                  <div className="service-img">
                    <dotlottie-player
                      src="https://lottie.host/dca08d13-cbea-4c02-8c0a-e24c7669c7b1/dgU4e9B67S.json"
                      background="transparent"
                      speed="1"
                      // className="animationhw"
                      style={{ width: "105px", height: "105px" }}
                      loop
                      autoplay
                    />
                  </div>
                  <div className="service-content">
                    <h2>
                      <Link> Book a Perfect Ride </Link>
                    </h2>
                    <p>Taxis, cars & more for your seamless by-road trips.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-5">
              <div className="col-12">
              <div className="additional-info p-4 border rounded">
                <h4 className="mb-3">Our Services</h4>
                <ul className="list-unstyled">
                  <li className="mb-2"><strong>Online Flight Booking:</strong> Find your perfect flight & take off on adventurous journeys.</li>
                  <li className="mb-2"><strong>Book Top Class Hotel:</strong> Book your dream hotel stay in an instant.</li>
                  <li><strong>Book a Perfect Ride:</strong> Taxis, cars & more for your seamless by-road trips.</li>
                </ul>
              </div>
            </div>
            </div> */}
          </div>
        </div>
      </section>

      <section id="gallery" className="gallery">
        <div className="container">
          <div className="gallery-details">
            <div className="gallary-header text-center">
              <h2>Find Your Perfect Escape</h2>
              <p>
                Where will you go next? Explore a world of exciting
                destinations!
              </p>
            </div>
            <div className="gallery-box">
              <div className="gallery-content">
                <div className="filtr-container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="filtr-item">
                        <img src={ImageUrl("g1.jpg")} alt="portfolio image" />
                        <div className="item-title">
                          <Link to="#"> china </Link>
                          <p>
                            <span>20 tours</span>
                            <span>15 places</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="filtr-item">
                        <img src={ImageUrl("g2.jpg")} alt="portfolio image" />
                        <div className="item-title">
                          <Link to="#"> venuzuala </Link>
                          <p>
                            <span>12 tours</span>
                            <span>9 places</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="filtr-item">
                        <img src={ImageUrl("g3.jpg")} alt="portfolio image" />
                        <div className="item-title">
                          <Link to="#"> brazil </Link>
                          <p>
                            <span>25 tours</span>
                            <span>10 places</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="filtr-item">
                            <img
                              src={ImageUrl("g4.jpg")}
                              alt="portfolio image"
                            />
                            <div className="item-title">
                              <Link to="#"> australia </Link>
                              <p>
                                <span>18 tours</span>
                                <span>9 places</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="filtr-item">
                            <img
                              src={ImageUrl("g5.jpg")}
                              alt="portfolio image"
                            />
                            <div className="item-title">
                              <Link to="#"> netherlands </Link>
                              <p>
                                <span>14 tours</span>
                                <span>12 places</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="filtr-item">
                            <img
                              src={ImageUrl("g6.jpg")}
                              alt="portfolio image"
                            />
                            <div className="item-title">
                              <Link to="#"> turkey </Link>
                              <p>
                                <span>14 tours</span>
                                <span>6 places</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* gallery end */}

      {/* discount-offer start */}
      <section className="discount-offer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="dicount-offer-content text-center">
                {/* <h2>
                  Prices Slashed by 40%! Don't Wait, Join Us by 7 March 2025!
                </h2> */}
                <h5 className="text-[#ffffff] font-semibold">
                  At TravelFly Hub, we believe that travel is not just about
                  reaching a destination; it’s about the journey, the
                  experiences, and the memories you create along the way.
                  Established with a passion for exploring the world, our
                  mission is to inspire and enable every wanderer to embark on
                  their own unique adventure.
                </h5>
                {/* <div className="campaign-timer">
                  <div id="timer">
                    <div className="time time-after" id="days">
                      <span>{days}</span>
                    </div>
                    <div className="time time-after" id="hours">
                      {hours}
                    </div>
                    <div className="time time-after" id="minutes">
                      {minutes}
                    </div>
                    <div className="time" id="seconds">
                      {seconds}
                    </div>
                  </div>
                </div> */}
                {/* <button className="book-btn">
                  <Link to="/about" className="navLink2">
                    LEARN MORE...
                  </Link>
                </button> */}
                <div className="about-btn">
                  <button className="about-view discount-offer-btn">
                  <Link to="/about" className="navLink2">
                    LEARN MORE
                  </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* discount-offer end */}

      <section id="pack" class="packages">
        <div class="container">
          <div class="gallary-header text-center">
            <h2>Find Your Perfect Travel Package</h2>
            <p>
              Explore our unforgettable travel packages for your dream trips!
            </p>
          </div>
          <div class="packages-content">
            <div class="row">
              <div class="col-md-4 col-sm-6">
                <div class="single-package-item">
                  <img src={ImageUrl("p1.jpg")} alt="package-place" />
                  <div class="single-package-item-txt">
                    <h3>
                      italy <span class="pull-right">$499</span>
                    </h3>
                    <div class="packages-para">
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> 3 Days 2 nights
                        </span>
                        <i class="fa fa-angle-right"></i> 5 star accomodation
                      </p>
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> transportation
                        </span>
                        <i class="fa fa-angle-right"></i> food facilities
                      </p>
                    </div>
                    <div class="packages-review">
                      <p>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <span>254 reviews</span>
                      </p>
                    </div>
                    {/* <div class="about-btn">
                      <button
                        onclick="/booking"
                        class="about-view packages-btn"
                      >
                        book now
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="single-package-item">
                  <img src={ImageUrl("p2.jpg")} alt="package-place" />
                  <div class="single-package-item-txt">
                    <h3>
                      england <span class="pull-right">$1499</span>
                    </h3>
                    <div class="packages-para">
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> 6 Days 7 nights
                        </span>
                        <i class="fa fa-angle-right"></i> 5 star accomodation
                      </p>
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> transportation
                        </span>
                        <i class="fa fa-angle-right"></i> Free food
                      </p>
                    </div>
                    <div class="packages-review">
                      <p>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <span>344 reviews</span>
                      </p>
                    </div>
                    {/* <div class="about-btn">
                      <button
                        onclick="/booking"
                        class="about-view packages-btn"
                      >
                        book now
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="single-package-item">
                  <img src={ImageUrl("p3.jpg")} alt="package-place" />
                  <div class="single-package-item-txt">
                    <h3>
                      france <span class="pull-right">$1199</span>
                    </h3>
                    <div class="packages-para">
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> 5 Days 6 nights
                        </span>
                        <i class="fa fa-angle-right"></i> 5 star accomodation
                      </p>
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> transportation
                        </span>
                        <i class="fa fa-angle-right"></i> food facilities
                      </p>
                    </div>
                    <div class="packages-review">
                      <p>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <span>544 reviews</span>
                      </p>
                    </div>
                    {/* <div class="about-btn">
                      <button
                        onclick="location.href='booking.html'"
                        class="about-view packages-btn"
                      >
                        book now
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="single-package-item">
                  <img src={ImageUrl("p4.jpg")} alt="package-place" />
                  <div class="single-package-item-txt">
                    <h3>
                      india <span class="pull-right">$799</span>
                    </h3>
                    <div class="packages-para">
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> 4 Days 5 nights
                        </span>
                        <i class="fa fa-angle-right"></i> 5 star accomodation
                      </p>
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> transportation
                        </span>
                        <i class="fa fa-angle-right"></i> food facilities
                      </p>
                    </div>
                    <div class="packages-review">
                      <p>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <span>625 reviews</span>
                      </p>
                    </div>
                    {/* <div class="about-btn">
                      <button
                        onclick="location.href='booking.html'"
                        class="about-view packages-btn"
                      >
                        book now
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="single-package-item">
                  <img src={ImageUrl("p5.jpg")} alt="package-place" />
                  <div class="single-package-item-txt">
                    <h3>
                      spain <span class="pull-right">$999</span>
                    </h3>
                    <div class="packages-para">
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> 4 Days 4 nights
                        </span>
                        <i class="fa fa-angle-right"></i> 5 star accomodation
                      </p>
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> transportation
                        </span>
                        <i class="fa fa-angle-right"></i> food facilities
                      </p>
                    </div>
                    <div class="packages-review">
                      <p>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <span>379 reviews</span>
                      </p>
                    </div>
                    {/* <div class="about-btn">
                      <button
                        onclick="location.href='booking.html'"
                        class="about-view packages-btn"
                      >
                        book now
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="single-package-item">
                  <img src={ImageUrl("p6.jpg")} alt="package-place" />
                  <div class="single-package-item-txt">
                    <h3>
                      thailand <span class="pull-right">$799</span>
                    </h3>
                    <div class="packages-para">
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> 5 Days 6 nights
                        </span>
                        <i class="fa fa-angle-right"></i> 5 star accomodation
                      </p>
                      <p>
                        <span>
                          <i class="fa fa-angle-right"></i> transportation
                        </span>
                        <i class="fa fa-angle-right"></i> food facilities
                      </p>
                    </div>
                    <div class="packages-review">
                      <p>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <span>447 reviews</span>
                      </p>
                    </div>
                    {/* <div class="about-btn">
                      <button
                        onclick="location.href='booking.html'"
                        class="about-view packages-btn"
                      >
                        book now
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="pack" className="packages">
        <div className="container">
          <div className="gallary-header text-center">
            <h2>Find Your Perfect Travel Package</h2>
            <p>
              Explore our unforgettable travel packages for your dream trips!
            </p>
          </div>
          <div className="packages-content">
            <div className="row">
              {packages.map((pkg, index) => (
                <div className="col-md-4 col-sm-6" key={index}>
                  <div className="single-package-item">
                    <img
                      src={pkg.image_url || "assets/images/default.jpg"}
                      alt="package-place"
                    />
                    <div className="single-package-item-txt">
                      <h3>
                        <span className="pull-right">${pkg.label}</span>
                      </h3>
                      <div className="packages-para">
                        <p>
                          <span>
                            <i className="fa fa-angle-right"></i> {pkg.name}
                          </span>
                          <i className="fa fa-angle-right"></i>{" "}
                          {pkg.country}
                        </p>
                        <p>
                          <span>
                            <i className="fa fa-angle-right"></i>{" "}
                            No of Hotels: {pkg.hotels}
                          </span>
                          <i className="fa fa-angle-right"></i> Transportation
                        </p>
                      </div>
                      <div className="about-btn">
                        <button
                          onClick={() => navigate("/booking")}
                          className="about-view packages-btn"
                        >
                          book now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="pack" className="packages">
        <div className="container">
          <div className="gallary-header text-center">
            <h2>Find Your Perfect Travel Package</h2>
            <p>
              Explore our unforgettable travel packages for your dream trips!
            </p>
          </div>
          <div className="packages-content">
            <div className="row">
              {packages.map((pkg, index) => (
                <div className="col-md-4 col-sm-6" key={index}>
                  <div className="single-package-item">
                    <img
                      src={pkg.photoUrls || "assets/images/b1.jpg"}
                      alt="package-place"
                    />
                    <div className="single-package-item-txt">
                      <h3>
                        <span className="pull-right">${pkg.label}</span>
                      </h3>
                      <div className="packages-para">
                        <p>
                          <span>
                            <i className="fa fa-angle-right"></i> {pkg.name}
                          </span>
                          <i className="fa fa-angle-right"></i> {pkg.country}
                        </p>
                        <p>
                          <span>
                            <i className="fa fa-angle-right"></i> No of Hotels: {pkg.hotels}
                          </span>
                          <i className="fa fa-angle-right"></i> Transportation
                        </p>
                      </div>
                      <div className="about-btn">
                        <button
                          onClick={() => navigate("/booking")}
                          className="about-view packages-btn"
                        >
                          book now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}
      {/* packages end */}

      {/* testemonial Start */}
      <section className="testimonial">
        <div className="container">
          <div className="gallery-header text-center">
            <h2>Clients Reviews</h2>
            <p>
              Duis aute irure dolor in velit esse cillum dolore eu fugiat nulla.
            </p>
          </div>

          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div className="home1-testm item" key={index}>
                <div className="home1-testm-single text-center">
                  <div className="home1-testm-img">
                    <img src={testimonial.img} alt="testimonial" />
                  </div>
                  <div className="home1-testm-txt">
                    <span className="section-icon">
                      <FaQuoteLeft />
                    </span>
                    <p>{testimonial.text}</p>
                    <h3>
                      <Link to="#">{testimonial.name}</Link>
                    </h3>
                    <h4>{testimonial.location}</h4>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {/* testemonial End */}

      {/* special-offer start */}
      <section id="spo" className="special-offer">
        <div className="container">
          <div className="special-offer-content">
            <div className="row">
              <div className="col-md-7 col-lg-8">
                <div className="single-special-offer">
                  <div className="single-special-offer-txt">
                    <h2>thailand</h2>
                    <div className="packages-para special-offer-para">
                      <p className="offer-para">
                        Experience the best of Thailand with our 5-day, 6-night
                        trip. Tour the bustling streets of Bangkok, visit the
                        iconic Grand Palace, relax on the golden sands, and
                        snorkel among colorful marine life while indulging in
                        delicious cuisine and local hospitality. Book your trip
                        today!
                      </p>
                    </div>
                    <div className="offer-btn-group">
                      <div className="about-btn">
                        <button className="about-view packages-btn offfer-btn">
                        <Link to="/" className="navLink2">
                    Make Tour
                  </Link>
                        </button>
                      </div>
                      <div className="about-btn">
                  <button className="about-view packages-btn">
                  <Link to="/contactus" className="navLink2">
                    Contact Us
                  </Link>
                  </button>
                </div>
                      {/* <div className="about-btn">
                        <button
                          onclick="location.href='booking.html'"
                          className="about-view packages-btn"
                        >
                          Contact Us
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-lg-4">
                <div className="single-special-offer">
                  <div className="single-special-offer-bg">
                    <img src={ImageUrl("offer-shape.png")} alt="offer-shape" />
                  </div>
                  <div className="single-special-shape-txt">
                    <h3>special offer</h3>
                    <h4>
                      <span>40%</span>
                      <br />
                      off
                    </h4>
                    <p>
                      <span>$999</span>
                      <br />
                      reguler $ 1450
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* special-offer end */}

      {/* blog start */}
      <section id="blog" className="blog">
        <div className="container">
          <div className="blog-details">
            <div className="gallary-header text-center">
              <h2>Latest News & Blogs</h2>
              <p>Travel News from all over the world</p>
            </div>
            <div className="blog-content">
              <div className="row">
                {[
                  {
                    id: 1,
                    date: '15 november 2017',
                    image: 'assets/images/b22.jpg',
                    title: "Travel Hacks for Saving Money on Your Next Adventure",
                    description:
                      "Let's explore clever hacks to save money on your next trip! Tips for finding budget-friendly flights, scoring deals on accommodation, and traveling like a pro without sacrificing the adventure!",
                    details: 'details1'
                  },
                  {
                    id: 2,
                    date: '15 november 2017',
                    image: 'assets/images/b20.webp',
                    title: "The Ultimate Guide to Packing Light for Your Trip",
                    description:
                      "Ditch the baggage blues! Learn how to travel smarter, not heavier. From choosing versatile clothing to mastering space-saving techniques, this guide will help you pack like a pro and maximize your travel tales!",
                    details: 'details2'
                  },
                  {
                    id: 3,
                    date: '15 november 2017',
                    image: 'assets/images/b19.jpg',
                    title: "Must-Have Travel Apps for a Smooth and Connected Journey",
                    description:
                      "Never get lost, stressed, or disconnected again! This guide reveals the must-have travel apps for a smooth and connected journey.",
                    details: 'details3'
                  }
                ].map((blog, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="thumbnail">
                      <h2>trending news <span>{blog.date}</span></h2>
                      <div className="thumbnail-img">
                        <img src={blog.image} alt="blog-img"/>
                        <div className="thumbnail-img-overlay"></div>
                      </div>
                      <div className="caption">
                       <div className="blog-txt">
                         <h3>
                          <Link>{blog.title}</Link>
                         </h3>
                         <p>
                           {blog.description}
                         </p>
                         {/* <Link onClick={() => handleReadMore(blog.image)}>Read More</Link> */}
                         <button onClick={() => handleReadMore(blog.image, blog.details)}>
                          <Link>Read More</Link>
                         </button>
                       </div>
                     </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="blog" className="blog">
        <div className="container">
          <div className="blog-details">
            <div className="gallary-header text-center">
              <h2>Latest News & Blogs</h2>
              <p>Travel News from all over the world</p>
            </div>
            <div className="blog-content">
              <div className="row">
                <div className="col-md-4">
                  <div className="thumbnail">
                    <h2>
                      trending news <span>15 november 2017</span>
                    </h2>
                    <div className="thumbnail-img">
                      <img src={ImageUrl("b1.jpg")} alt="blog-img" />
                      <div className="thumbnail-img-overlay"></div>
                    </div>

                    <div className="caption">
                      <div className="blog-txt">
                        <h3>
                          <Link to="/blogDetails">
                            Travel Hacks for Saving Money on Your Next Adventure
                          </Link>
                        </h3>
                        <p>
                          Let's explore clever hacks to save money on your next
                          trip! Tips for finding budget-friendly flights,
                          scoring deals on accommodation, and traveling like a
                          pro without sacrificing the adventure!
                        </p>
                        <Link to="/blogDetails">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" col-md-4">
                  <div className="thumbnail">
                    <h2>
                      trending news <span>15 november 2017</span>
                    </h2>
                    <div className="thumbnail-img">
                      <img src={ImageUrl("b2.jpg")} alt="blog-img" />
                      <div className="thumbnail-img-overlay"></div>
                    </div>
                    <div className="caption">
                      <div className="blog-txt">
                        <h3>
                          <Link to="/blogDetails">
                            The Ultimate Guide to Packing Light for Your Trip
                          </Link>
                        </h3>
                        <p>
                          Ditch the baggage blues! Learn how to travel smarter,
                          not heavier. From choosing versatile clothing to
                          mastering space-saving techniques, this guide will
                          help you pack like a pro and maximize your travel
                          tales!
                        </p>
                        <Link to="/blogDetails">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="thumbnail">
                    <h2>
                      trending news <span>15 november 2017</span>
                    </h2>
                    <div className="thumbnail-img">
                      <img src={ImageUrl("b3.jpg")} alt="blog-img" />
                      <div className="thumbnail-img-overlay"></div>
                    </div>
                    <div className="caption">
                      <div className="blog-txt">
                        <h3>
                          <Link to="/blogDetails">
                            Must-Have Travel Apps for a Smooth and Connected
                            Journey
                          </Link>
                        </h3>
                        <p>
                          Never get lost, stressed, or disconnected again! This
                          guide reveals the must-have travel apps for a smooth
                          and connected journey.
                        </p>
                        <Link to="/blogDetails">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* blog end */}

      {/* subscribe start */}
      {/* <section id="subs" className="subscribe">
        <div className="container">
          <div className="subscribe-title text-center">
            <h2>Don't Be Left Behind: Join Our Subscriber List Today!</h2>
            <p>
            Subscribe now and stay informed about the best offers for your next trip!
            </p>
          </div>
          <form>
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="custom-input-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your Email Here"
                  />
                  <button className="appsLand-btn subscribe-btn">
                    Subscribe
                  </button>
                  <div className="clearfix"></div>
                  <i className="fa fa-envelope"></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section> */}

      <section id="subs" className="subscribe">
        <div className="container">
          <div className="subscribe-title text-center">
            <h2>Don't Be Left Behind: Join Our Subscriber List Today!</h2>
            <p>
              Subscribe now and stay informed about the best offers for your
              next trip!
            </p>
          </div>
          <form onSubmit={handleSubscribe}>
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="custom-input-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your Email Here"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button type="submit" className="appsLand-btn subscribe-btn">
                    Subscribe
                  </button>
                  <div className="clearfix"></div>
                  <i className="fa fa-envelope"></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      {/* subscribe end */}
    </>
  );
};

export default Home;
